import { stytemTimezone, timezoneList } from 'constants/timezones';

import { Autocomplete } from '../common';

import type { SxProps, Theme } from '@mui/material';

interface TimezoneAutocompleteProps {
  disabled?: boolean;
  onChange: (value: string | null) => void;
  sx?: SxProps<Theme>;
  value: string | null;
}

const DEFAULT_TIMEZONE_VALUE = 'DEFAULT';

const options = [
  {
    label: stytemTimezone.label,
    value: DEFAULT_TIMEZONE_VALUE,
  },
  ...timezoneList.map((timezone) => ({
    label: timezone.label,
    value: timezone.timezone,
  })),
];
const TimezoneAutocomplete = ({
  value,
  onChange,
  sx,
  disabled,
}: TimezoneAutocompleteProps) => {
  const selectedValue = value ?? DEFAULT_TIMEZONE_VALUE;
  const selectedOption = options.find(
    (option) => option.value === selectedValue,
  )!;
  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      onChange={(_, selected) =>
        onChange(
          !selected || selected.value === DEFAULT_TIMEZONE_VALUE
            ? null
            : selected.value,
        )
      }
      options={options}
      sx={sx}
      value={selectedOption}
    />
  );
};

export default TimezoneAutocomplete;
