import { ThemeProvider as GoodDataThemeProvider } from '@gooddata/sdk-ui-theme-provider';
import {
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { createContext, useEffect, useMemo, useState } from 'react';

import { ThemeMode } from 'tools/constants/profile';

import { useProfile } from 'hooks';
import { getTheme as getGoodDataTheme } from 'styles/goodDataTheme';
import { getTheme as getMuiTheme } from 'styles/muiTheme';

export const ThemeModeContext = createContext<
  [ThemeMode, (themeMode: ThemeMode) => void]
>([
  ThemeMode.Light,
  /* istanbul ignore next */
  () => {},
]);

interface Props {
  children: React.ReactNode;
}
export const ThemeModeProvider = ({ children }: Props) => {
  const { theme } = useProfile();
  const [themeMode, setThemeMode] = useState<ThemeMode>(() => ThemeMode.System);

  // check system preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  /**
   * @todo get "preferred theme" outside this Provider (https://app.clickup.com/t/2339756/CK-22592)
   */
  const mode = (() => {
    if (themeMode === ThemeMode.System)
      return prefersDarkMode ? ThemeMode.Dark : ThemeMode.Light;
    return themeMode;
  })();

  // -------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (theme) setThemeMode(theme);
  }, [theme]);

  useEffect(() => {
    if (mode === ThemeMode.Dark) document.body.classList.add('ck-dark');
    return () => {
      document.body.classList.remove('ck-dark');
    };
  }, [mode]);

  // -------------------------------------------------------------------------------------------------
  const muiTheme = useMemo(() => getMuiTheme(mode), [mode]);
  const goodDataTheme = useMemo(() => getGoodDataTheme(mode), [mode]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <GoodDataThemeProvider theme={goodDataTheme}>
        <ThemeModeContext.Provider value={[themeMode!, setThemeMode]}>
          {children}
        </ThemeModeContext.Provider>
      </GoodDataThemeProvider>
    </MuiThemeProvider>
  );
};
