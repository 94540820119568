import { SWRConfig as SWRConfigLIB } from 'swr';

import axios from 'actions';

import { USERS_BASE_URL } from 'tools/urls/users';

// https://swr.vercel.app/docs/advanced/cache#localstorage-based-persistent-cache
const swrProvider = () => {
  const storageKey = 'coursekey_cache';

  // Load cache
  const map = new Map<string, string>(
    JSON.parse(sessionStorage.getItem(storageKey) || '[]'),
  );

  // Save cache
  window.addEventListener('beforeunload', () => {
    // TODO: Temporal workaround to prevent Users API from being cached
    // Follow-up ticket: https://app.clickup.com/t/2r6d5fj
    // Maintenance docs: https://app.clickup.com/2339756/v/dc/27cxc-3808/27cxc-43125
    // Troubleshooting docs: https://app.clickup.com/2339756/v/dc/27cxc-3808/27cxc-42985
    const appCache = JSON.stringify(
      Array.from(map.entries()).filter(
        ([key]) => key !== USERS_BASE_URL && key !== `$swr$${USERS_BASE_URL}`,
      ),
    );

    sessionStorage.setItem(storageKey, appCache);
  });

  return map;
};

const SWRConfig = ({ children }: { children: React.ReactNode }) => {
  return (
    <SWRConfigLIB
      value={{
        errorRetryCount: 0,
        fetcher: (url: string) =>
          axios(url)
            .then(({ data }) => data)
            .catch(({ response }) => Promise.reject(response.data.error)),
        provider: swrProvider,
        revalidateOnFocus: false,
      }}
    >
      {children}
    </SWRConfigLIB>
  );
};

export default SWRConfig;
