import { createContext, useEffect, useState } from 'react';

import { initSocket } from 'helpers/socket';

import { useIsProfileFetched } from 'hooks';

interface Context {
  socket: any;
}

export const SocketContext = createContext<Context | null>(null);

interface Props {
  children: React.ReactNode;
}

export const SocketProvider = ({ children }: Props) => {
  const [socket] = useState(() => initSocket());
  const isAuth = useIsProfileFetched();

  useEffect(() => {
    if (isAuth) {
      socket.open();
    } else {
      socket.close();
    }
  }, [isAuth, socket]);

  useEffect(() => {
    socket.on('connect', () => {
      window.gtag('event', 'socket_connected', {
        event_category: 'socket',
      });

      const socketID = localStorage.getItem('socketID');
      if (socketID) {
        socket.emit('changeTimeline', socketID);
      }
      localStorage.setItem('socketID', socket.id);
    });

    socket.on('disconnect', () => {
      window.gtag('event', 'socket_disconnected', {
        event_category: 'socket',
      });
    });

    socket.on('error', () => {
      window.gtag('event', 'socket_error', {
        event_category: 'socket',
      });
    });

    socket.on('connect_error', () => {
      window.gtag('event', 'socket_connect_error', {
        event_category: 'socket',
      });
    });

    socket.on('reconnect_error', () => {
      window.gtag('event', 'socket_reconnect_error', {
        event_category: 'socket',
      });
    });

    // wildcard to perform acknowledgements
    socket.on('*', ({ data }: { data: [string, object, () => void] }) => {
      const ack = data[2];
      if (ack) ack();
    });
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
