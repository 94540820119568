import { useContext, useEffect } from 'react';
import { useResolvedPath } from 'react-router-dom';

import { BreadcrumbsContext, SocketContext, ThemeModeContext } from 'context';

export const useBreadcrumbs = (name?: string | null, url?: string) => {
  const context = useContext(BreadcrumbsContext);
  const { pathname } = useResolvedPath('');

  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider');
  }

  const { setBreadcrumbs } = context;
  useEffect(() => {
    if (name) {
      setBreadcrumbs((breadcrumbs) => [
        ...breadcrumbs,
        { name, url: url || pathname },
      ]);
    }
    return () => {
      if (name) {
        setBreadcrumbs((breadcrumbs) => breadcrumbs.slice(0, -1));
      }
    };
  }, [pathname, name, setBreadcrumbs, url]);
  return context;
};

export const useSocket = () => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const useSocketEvent = (
  eventName: string,
  callback: (...args: any) => void,
) => {
  const { socket } = useSocket();

  useEffect(() => {
    socket.on(eventName, callback);
    return () => {
      socket.off(eventName, callback);
    };
  }, [callback, eventName, socket]);
};

export const useSocketEmit = () => {
  const { socket } = useSocket();
  return (event: string, ...args: any[]) => socket.emit(event, ...args);
};

export const useThemeMode = () => useContext(ThemeModeContext);
