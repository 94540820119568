import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { createPaymentMethod } from 'actions/paymentActions';

import { IFieldType, ValidationMessage } from 'tools/constants/payment';
import { PaymentType } from 'tools/constants/payPortal';
import { ThemeMode } from 'tools/constants/profile';

import {
  CARDKNOX_FIELDS_KEY,
  CARDKNOX_LIB_VERSION,
  CARDKNOX_SOFTWARE_NAME,
  CARDKNOX_SOFTWARE_VERSION,
} from 'helpers/env';
import { formValidator } from 'helpers/formValidator';
import { formatCardExpiry } from 'helpers/paymentCard';

import CardknoxIField from 'components/account/MyPayments/CardknoxIField';
import { Select } from 'components/common';
import Spinner from 'components/spinners/Spinner';

import { useIsLightMode, useThemeMode } from 'hooks';
import useScript from 'hooks/useScript';
import {
  allowOnlyAlphabets,
  allowOnlyNumeric,
  getErrorMsgFromAPIResponse,
  isFirefox,
} from 'pages/users/user/payments/utils';
import { gray2, gray3, gray7, gray8 } from 'styles/colors';

import type {
  PaymentMethodCreateRequest,
  PaymentMethodForm,
} from 'types/payment';

const SET_USER_ID = 'SET_USER_ID';

declare global {
  interface Window {
    /** This interface can be used to let mobile know the app is ready */
    FormReady?: {
      postMessage: (data: string) => void;
    };
    getTokens: (arg1: () => void, arg2: () => void) => void;
    OnCancel?: {
      postMessage: (data: string) => void;
    };
    OnError?: {
      postMessage: (data: string) => void;
    };
    OnSuccess?: {
      postMessage: (data: string) => void;
    };
    setAccount: (arg1: string, arg2: string, arg3: string) => void;
    /** This function is used to enable the mobile app to set the form default data */
    setUserData: (
      /** This parameter is a json of the mobile data, represented on the `MobileData` interface */
      data: string,
    ) => void;
  }
}

// setting up the send form function to mobile insert data
window.setUserData = (data) => {
  // dispatching an event that will be listened inside the component
  window.dispatchEvent(
    new CustomEvent(SET_USER_ID, { detail: JSON.parse(data).toString() }),
  );
};

const AddPaymentMethod = () => {
  const isScriptLoaded = useScript(
    `https://cdn.cardknox.com/ifields/${CARDKNOX_LIB_VERSION}/ifields.min.js`,
  );

  const isLight = useIsLightMode();
  const { enqueueSnackbar } = useSnackbar();
  const cardTokenRef: any = useRef();
  const ACHTokenRef: any = useRef();
  const CVVTokenRef: any = useRef();

  const [paymentType, setPaymentType] = useState(PaymentType.PayWithBank);
  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState('savings');
  const [isEmptyAccountNumber, setIsEmptyAccountNumber] = useState(true);
  const [isEmptyCardNumber, setIsEmptyCardNumber] = useState(true);
  const [isEmptyCVV, setIsEmptyCVV] = useState(true);
  const [userID, setUserID] = useState('');

  const { 1: setThemeMode } = useThemeMode();

  useEffect(() => {
    if (isScriptLoaded) {
      window.setAccount(
        CARDKNOX_FIELDS_KEY,
        CARDKNOX_SOFTWARE_NAME,
        CARDKNOX_SOFTWARE_VERSION,
      );
      setLoading(false);
    }
  }, [isScriptLoaded]);

  useEffectOnce(() => {
    const setUserData = (event: Event) => {
      setUserID((event as CustomEvent).detail);
    };
    setThemeMode(ThemeMode.Light);
    window.FormReady?.postMessage?.('');
    window.addEventListener(SET_USER_ID, setUserData);
    return () => {
      // cleaning up custom events listener
      window.removeEventListener(SET_USER_ID, setUserData);
    };
  });

  const onCancel = () => {
    window.OnCancel?.postMessage?.('Cancel');
  };

  const nameLabel = useMemo(() => {
    return paymentType === PaymentType.PayWithBank
      ? 'Name On Account'
      : 'Name On Card';
  }, [paymentType]);

  /**
   * @description set default values of payment form
   * @returns PaymentMethodForm object
   */
  const setDefaultValues = (): PaymentMethodForm => {
    return {
      expiry: '',
      name: '',
      routingNumber: '',
    };
  };

  const { register, handleSubmit, getValues, setValue, control } =
    useForm<PaymentMethodForm>({
      defaultValues: setDefaultValues(),
    });

  const cardKnoxInputStyles = useMemo(
    () => ({
      backgroundColor: isLight ? 'white' : gray2,
      border: '1px solid',
      borderColor: isLight ? gray7 : gray3,
      borderRadius: '6px',
      color: isLight ? gray3 : gray8,
      fontSize: '14px',
      fontWeight: 500,
      height: '20px',
      outline: 'none',
      padding: '10px 16px',
      width: isFirefox() ? '-moz-available' : '-webkit-fill-available',
    }),
    [isLight],
  );

  /**
   * Handles the change of payment type and updates the state accordingly.
   * @param {string} val - The new payment type value.
   */
  const handlePaymentTypeChange = (val: string) => {
    setLoading(true);
    setValue('expiry', '');
    setValue('name', '');
    setValue('routingNumber', '');
    setPaymentType(
      val === PaymentType.PayWithBank
        ? PaymentType.PayWithBank
        : PaymentType.PayWithCard,
    );
  };

  /**
   * Updates the state of whether the account number is empty based on the provided data.
   * @param {Object} data - The data object containing information about the account number.
   */
  const onUpdateAccountNumber = (data: any) => {
    if (data.achIsEmpty) {
      setIsEmptyAccountNumber(true);
    } else {
      setIsEmptyAccountNumber(false);
    }
  };

  /**
   * Updates the state of whether the card number is empty based on the provided data.
   * @param {Object} data - The data object containing information about the card number.
   */
  const onUpdateCardNumber = (data: any) => {
    if (data.cardNumberLength === 0) {
      setIsEmptyCardNumber(true);
    } else {
      setIsEmptyCardNumber(false);
    }
  };

  /**
   * Updates the state of whether the CVV is empty based on the provided data.
   * @param {Object} data - The data object containing information about the CVV.
   */
  const onUpdateCVV = (data: any) => {
    if (data.cvvIsEmpty) {
      setIsEmptyCVV(true);
    } else {
      setIsEmptyCVV(false);
    }
  };

  /**
   * Saves the payment method by sending a request to the API with the provided form data.
   * @returns {Promise<void>} A promise that resolves when the payment method is successfully saved.
   */
  const savePaymentMethod = async () => {
    const formData = getValues();

    const apiData: PaymentMethodCreateRequest = {
      name: formData.name,
      tokenType: paymentType === PaymentType.PayWithBank ? 'check' : 'cc',
    };
    if (paymentType === PaymentType.PayWithBank) {
      if (ACHTokenRef?.current?.value) {
        apiData.achToken = ACHTokenRef?.current?.value;
        apiData.routing = formData.routingNumber;
        apiData.accountType = accountType;
      } else {
        window.OnError?.postMessage?.('Error in ACH token');
        setLoading(false);
        return enqueueSnackbar(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } else {
      if (cardTokenRef?.current?.value && CVVTokenRef?.current?.value) {
        apiData.cardToken = cardTokenRef?.current?.value;
        apiData.cvvToken = CVVTokenRef?.current?.value;
        apiData.exp = formData.expiry.replace('/', '');
      } else {
        window.OnError?.postMessage?.('Error in Card or CVV token');
        setLoading(false);
        return enqueueSnackbar(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    }

    if (userID === '') {
      window.OnError?.postMessage?.('User ID is missing');
      setLoading(false);
      return enqueueSnackbar(ValidationMessage.SOMETHING_WENT_WRONG);
    }

    try {
      const apiParams = {
        userID: userID,
      };
      await createPaymentMethod(apiParams, apiData);
      window.OnSuccess?.postMessage?.(ValidationMessage.PAYMENT_METHOD_ADDED);
    } catch (error) {
      const errMessage = getErrorMsgFromAPIResponse(error);
      window.OnError?.postMessage?.(errMessage);
      enqueueSnackbar(errMessage);
    }
    setLoading(false);
  };

  /**
   * Validates the form data based on the payment type and sets error messages accordingly.
   * @returns {boolean} Indicates whether the form data is valid.
   */
  const validateData = () => {
    const formData = getValues();
    let isValid = true;
    let invalidMessage = '';
    if (paymentType === PaymentType.PayWithBank) {
      if (isEmptyAccountNumber) {
        isValid = false;
        invalidMessage = ValidationMessage.ACCOUNT_NUMBER_REQUIRED;
      }
      if (formData.routingNumber === '') {
        isValid = false;
        invalidMessage = ValidationMessage.ROUTING_NUMBER_REQUIRED;
      }
      if (formData.name === '') {
        isValid = false;
        invalidMessage = ValidationMessage.NAME_ON_ACCOUNT_REQUIRED;
      }
    } else {
      if (isEmptyCVV) {
        isValid = false;
        invalidMessage = ValidationMessage.CVV_REQUIRED;
      }
      if (isEmptyCardNumber) {
        isValid = false;
        invalidMessage = ValidationMessage.CARD_NUMBER_REQUIRED;
      }
      if (formData.name === '') {
        isValid = false;
        invalidMessage = ValidationMessage.NAME_ON_CARD_REQUIRED;
      }
    }
    if (!isValid) {
      window.OnError?.postMessage?.(invalidMessage);
      enqueueSnackbar(invalidMessage);
    }
    return isValid;
  };

  /**
   * Handles form submission by validating the form data and obtaining payment tokens if the data is valid.
   */
  const onFormSubmit = async () => {
    if (validateData()) {
      setLoading(true);
      window.getTokens(
        () => {
          savePaymentMethod();
        },
        () => {
          enqueueSnackbar(ValidationMessage.SOMETHING_WENT_WRONG);
        },
      );
    }
  };

  return (
    <Paper elevation={0} sx={{ m: 3, px: 2, py: 3 }}>
      {isScriptLoaded && loading && <Spinner />}
      <Typography variant="h5">Add Payment Method</Typography>
      <Box mt={3}>
        <form
          id="add-payment-method-mobile-app"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <RadioGroup
            onChange={(_, val) => handlePaymentTypeChange(val)}
            row
            sx={{
              columnGap: '24px',
              mb: 3,
            }}
            value={paymentType}
          >
            <FormControlLabel
              control={<Radio />}
              label={
                <Typography variant="subtitle1">
                  {PaymentType.PayWithBank}
                </Typography>
              }
              sx={{
                alignItems: 'center',
              }}
              value={PaymentType.PayWithBank}
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <Typography variant="subtitle1">
                  {PaymentType.PayWithCard}
                </Typography>
              }
              sx={{
                alignItems: 'center',
              }}
              value={PaymentType.PayWithCard}
            />
          </RadioGroup>
          <Box columnGap={3} display="flex">
            <Controller
              control={control}
              defaultValue=""
              name="name"
              render={() => (
                <TextField
                  fullWidth
                  InputProps={{
                    ...register('name'),
                  }}
                  label={`${nameLabel} *`}
                  onKeyDown={allowOnlyAlphabets}
                  placeholder={nameLabel}
                  required
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box columnGap={3} mt={3}>
            {paymentType === 'Pay With Bank Account' ? (
              <>
                <Box width={'100%'}>
                  <Select
                    fullWidth
                    id="bank-account-type"
                    label="Account Type*"
                    onChange={(type) => setAccountType(type)}
                    options={[
                      { label: 'Saving', value: 'savings' },
                      {
                        label: 'Checking',
                        value: 'checking',
                      },
                    ]}
                    style={{ marginBottom: 24 }}
                    value={accountType}
                  />
                </Box>
                <Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="name"
                    render={() => (
                      <TextField
                        fullWidth
                        InputProps={{
                          ...register('routingNumber'),
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        label="Routing Number *"
                        onKeyDown={allowOnlyNumeric}
                        placeholder="Routing Number"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
                <Box mt={3}>
                  <Typography pb={0.5} variant="subtitle2">
                    Account Number *
                  </Typography>
                  {isScriptLoaded && (
                    <CardknoxIField
                      inputStyles={cardKnoxInputStyles}
                      onLoad={() => {
                        setLoading(false);
                      }}
                      onUpdate={onUpdateAccountNumber}
                      placeholder="Account Number"
                      ref={ACHTokenRef}
                      type={IFieldType.ACH_TYPE}
                    />
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Typography pb={0.5} variant="subtitle2">
                    Card Number *
                  </Typography>
                  {isScriptLoaded && (
                    <CardknoxIField
                      inputStyles={cardKnoxInputStyles}
                      onLoad={() => {
                        setLoading(false);
                      }}
                      onUpdate={onUpdateCardNumber}
                      placeholder="Card Number"
                      ref={cardTokenRef}
                      type={IFieldType.CARD_TYPE}
                    />
                  )}
                </Box>
                <Box display={'flex'} mt={2.5}>
                  <TextField
                    InputProps={{
                      ...register('expiry', {
                        pattern: formValidator.cardExpiry,
                      }),
                      onKeyUp: formatCardExpiry,
                    }}
                    label="Expiration *"
                    placeholder="MM/YY"
                    required
                    sx={{ width: '136px' }}
                    variant="outlined"
                  />
                  <Box ml={3} width={'180px'}>
                    <Typography pb={0.5} variant="subtitle2">
                      CVV *
                    </Typography>
                    {isScriptLoaded && (
                      <CardknoxIField
                        inputStyles={cardKnoxInputStyles}
                        onLoad={() => setLoading(false)}
                        onUpdate={onUpdateCVV}
                        placeholder="CVV"
                        ref={CVVTokenRef}
                        type={IFieldType.CVV_TYPE}
                      />
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box mt={3}>
            <Button
              disabled={loading}
              fullWidth
              type="submit"
              variant="contained"
            >
              Add Payment Method
            </Button>
            <Button
              disabled={loading}
              fullWidth
              onClick={onCancel}
              sx={{ mt: 2 }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

export default AddPaymentMethod;
