import { LicenseInfo, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';

import { MUILicenseKey } from 'helpers/env';

import type { DateTime } from 'luxon';

// Setting Up MUI License
LicenseInfo.setLicenseKey(MUILicenseKey);

/**
 * Custom Adapter for MUI Date Picker
 * @deprecated This is suppose to be a temporary fix to start the week on Sunday instead of Monday.
 * When updating the library, please be careful since there ar some know issues with this override and the latest version of x-date-pickers-pro.
 * @see https://github.com/mui/material-ui/issues/30591#issuecomment-1377997824
 * @see https://github.com/mui/mui-x/issues/9984#issuecomment-1768499658
 *
 * Waiting on the solution of this issue to remove this override:
 * @see https://github.com/mui/mui-x/issues/10805
 */
class Adapter extends AdapterLuxon {
  public getWeekdays = () => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  };

  getWeekArray = (date: DateTime) => {
    const days = 42; // 6 weeks

    let weeks: DateTime[][] = [];
    new Array(days)
      .fill(0)
      .map((_, i) => i)
      .map((day) =>
        date
          .startOf('month')
          .startOf('week')
          .minus({ days: 1 })
          .plus({ days: day }),
      )
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    weeks = weeks.filter((week) => {
      // do not allow weeks with start or end outside of current month
      return (
        week[0].hasSame(date, 'month') ||
        week[week.length - 1].hasSame(date, 'month')
      );
    });

    return weeks;
  };
}

const MUILicenseConfig = ({ children }: { children: React.ReactNode }) => {
  return (
    <LocalizationProvider dateAdapter={Adapter}>
      {children}
    </LocalizationProvider>
  );
};

export default MUILicenseConfig;
