import { Box, TextField, type TextFieldProps, Typography } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

import { useTimezoneContext } from 'components/timezones';

export interface LocatedDateTimePickerProps
  extends Omit<DateTimePickerProps<Date, Date>, 'renderInput'> {
  TextFieldProps?: TextFieldProps;
}

const LocatedDateTimePicker = forwardRef(function LocatedDateTimePicker({
  TextFieldProps,
  ...props
}: LocatedDateTimePickerProps) {
  const { currentTimeZoneAbbreviation } = useTimezoneContext();

  /**
   * This is a hack to get around the fact that the MUI DatePicker component requires luxon DateTime objects after our new implementation
   * @todo change all teh applications to use luxon DateTime objects
   */
  const normalizedProps: Omit<
    DateTimePickerProps<DateTime, DateTime>,
    'renderInput'
  > = {
    ...props,
    defaultCalendarMonth:
      props.defaultCalendarMonth &&
      DateTime.fromJSDate(props.defaultCalendarMonth),

    /**
     * @todo Need to support getClockLabelText property. Marked as a follow up cause its more complex
     */
    getClockLabelText: undefined,

    /**
     * @todo Need to support getOpenDialogAriaText property. Marked as a follow up cause its more complex
     */
    getOpenDialogAriaText: undefined,

    maxDate: props.maxDate && DateTime.fromJSDate(props.maxDate),

    maxDateTime: props.maxDateTime && DateTime.fromJSDate(props.maxDateTime),

    maxTime: props.maxTime && DateTime.fromJSDate(props.maxTime),
    minDate: props.minDate && DateTime.fromJSDate(props.minDate),
    minDateTime: props.minDateTime && DateTime.fromJSDate(props.minDateTime),
    minTime: props.minTime && DateTime.fromJSDate(props.minTime),

    onAccept: (date) => {
      return props.onAccept?.(date?.toJSDate() ?? null);
    },
    onChange: (date, keyboardInputValue) => {
      return props.onChange(date?.toJSDate() ?? null, keyboardInputValue);
    },
    onError: (reason, value) => {
      return props.onError?.(reason, value?.toJSDate() ?? null);
    },

    onMonthChange: (date) => {
      return props.onMonthChange?.(date?.toJSDate() ?? null);
    },

    onYearChange: (date) => {
      return props.onYearChange?.(date?.toJSDate() ?? null);
    },

    /**
     * @todo Need to support render day property. Marked as a follow up cause its more complex
     */
    renderDay: undefined,

    shouldDisableDate:
      props.shouldDisableDate &&
      ((date) => {
        return props.shouldDisableDate!(date.toJSDate());
      }),

    shouldDisableMonth:
      props.shouldDisableMonth &&
      ((date) => {
        return props.shouldDisableMonth!(date.toJSDate());
      }),

    shouldDisableYear:
      props.shouldDisableYear &&
      ((date) => {
        return props.shouldDisableYear!(date.toJSDate());
      }),
    /**
     * @todo Need to support openTo property. Marked as a follow up cause its more complex
     */
    ToolbarComponent: undefined,
    value: props.value ? DateTime.fromJSDate(new Date(props.value)) : null,
  };

  return (
    <DateTimePicker
      {...normalizedProps}
      renderInput={(props) => (
        <TextField
          {...TextFieldProps}
          {...props}
          InputProps={{
            ...TextFieldProps?.InputProps,
            ...props.InputProps,
            endAdornment: (
              <Box alignItems="center" display="flex" gap={0}>
                <Typography
                  fontStyle="italic"
                  variant="caption"
                  whiteSpace="nowrap"
                >
                  {currentTimeZoneAbbreviation}
                </Typography>
                {props.InputProps?.endAdornment}
              </Box>
            ),
          }}
        />
      )}
    />
  );
});

export default LocatedDateTimePicker;
