import { BASE_URL } from '.';

const API_VERSION = 'v3';

export const getFormsSummaryURL = ({
  formID,
}: {
  formID: string;
  schoolIDs?: string[];
}) => `${BASE_URL}/${API_VERSION}/forms/form/${formID}/responses/summary`;

export const getResponsesURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/responses/list`;

export const getResponseURL = ({ responseID }: { responseID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/responses/${responseID}`;
export const getSendResponseNotificationURL = ({
  responseID,
}: {
  responseID: string;
}) => `${BASE_URL}/${API_VERSION}/forms/responses/${responseID}/notify`;

export const getFormURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}`;

export const getCreateExternalFormToken = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/external/${formID}/token`;

export const getReviewResponseURL = ({ responseID }: { responseID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/responses/${responseID}/review`;

export const getUpdateFormSchemaURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/schema`;

export const getDuplicateFormURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/clone`;

export const PUBLIC_FORM_URL = `${BASE_URL}/${API_VERSION}/forms/submissions/external`;

export const getSubmissionInfoFromFormURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/submissions/form/${formID}`;

export const getSubmissionInfoFromResponseURL = ({
  responseID,
}: {
  responseID: string;
}) => `${BASE_URL}/${API_VERSION}/forms/submissions/response/${responseID}`;

export const createSubmissionFromFormURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/submissions/form/${formID}`;

export const createSubmissionFromResponseURL = ({
  responseID,
}: {
  responseID: string;
}) => `${BASE_URL}/${API_VERSION}/forms/submissions/response/${responseID}`;

export const FORM_LIST_URL = `${BASE_URL}/${API_VERSION}/forms/list`;

export const CREATE_FORM_URL = `${BASE_URL}/${API_VERSION}/forms`;

export const CREATE_FORM_FOLDER_URL = `${BASE_URL}/${API_VERSION}/forms/folders`;

export const GET_FORM_FOLDERS_URL = `${BASE_URL}/${API_VERSION}/forms/folders`;

export const getFormMetadataURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/metadata`;

export const getFormUsersURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/users`;

export const getSendFormURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/send`;

export const GET_ME_RESPONSES_URL = `${BASE_URL}/${API_VERSION}/forms/me/responses`;
export const getMeResponseByIDURl = ({ responseID }: { responseID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/me/responses/response/${responseID}`;

export const GET_FORMS_GLOBAL_RESPONSES_URL = `${BASE_URL}/${API_VERSION}/forms/responses/list`;

export const getFormAnalyticsURL = ({ formID }: { formID: string }) =>
  `${BASE_URL}/${API_VERSION}/forms/form/${formID}/responses/analytics`;
