import { BASE_URL } from '.';

const API_VERSION = 'v3';

export const NOTIFICATION_BASE_URL = `${BASE_URL}/${API_VERSION}/notificationLog`;
export const NOTIFICATION_STATUS_URL = `${NOTIFICATION_BASE_URL}/status/isRead`;
export const NOTIFICATION_UNREAD_URL = `${NOTIFICATION_BASE_URL}/unread`;

export const getNotificationsUrl = ({
  page,
  size,
}: {
  page?: number;
  size?: number;
} = {}) => `${NOTIFICATION_BASE_URL}?page=${page}&size=${size}`;
