import { DateTime, guessSystemTimezone } from 'helpers/datetime';

import type { Zone } from 'luxon';

interface Timezone {
  label: string;
  timezone: string;
}

const baseTimestamp = new Date().getTime();

export function formatTZLabel(tz: Zone) {
  const abbr = tz.offsetName(baseTimestamp, { format: 'short' })!;
  // only consider abbreviations without GMT as starting characters
  const validAbbr = abbr.match(/^GMT/) ? '' : ` ${abbr} -`;
  const offset = tz.formatOffset(baseTimestamp, 'techie');
  const name = tz.name;
  const label = `(GMT${offset})${validAbbr} ${name}`;
  return label;
}

const USA_TIMEZONES = [
  'America/New_York', //GTM-4
  'America/Chicago', //GTM-5
  'America/Denver', //GTM-6
  'America/Los_Angeles', //GTM-7
  'America/Phoenix', //GTM-7
  'America/Anchorage', //GTM-8
  'Pacific/Honolulu', //GTM-10
];

// List of timezones
export const timezoneList: Timezone[] =
  /**
   * this line is to support older browsers
   * @todo remove this line after we upgrade typescript to the latest version
   * */
  (
    'supportedValuesOf' in Intl &&
    typeof (Intl as any).supportedValuesOf === 'function'
      ? (Intl as any).supportedValuesOf('timeZone')
      : USA_TIMEZONES
  )
    .map((tz: string) => DateTime.now().setZone(tz).zone)
    .filter((tz: Zone) => tz.isValid)
    .sort((a: Zone, b: Zone) => {
      const aIndex = USA_TIMEZONES.indexOf(a.name);
      const bIndex = USA_TIMEZONES.indexOf(b.name);
      if (aIndex !== -1 && bIndex !== -1) {
        // if both are on the list, sort by the list order
        return aIndex - bIndex;
      } else if (aIndex !== -1) {
        // if only A is on the list, put it first
        return -1;
      } else if (bIndex !== -1) {
        // if only B is on the list, put it first
        return 1;
      } else {
        // otherwise sort by offset
        return a.offset(baseTimestamp) - b.offset(baseTimestamp);
      }
    })
    .map((tz: Zone) => {
      return {
        label: formatTZLabel(tz),
        timezone: tz.name,
      };
    });

const systemTz = DateTime.now().setZone(guessSystemTimezone()).zone;

export const stytemTimezone: Timezone = {
  label: `(System Default) ${formatTZLabel(systemTz)}`,
  timezone: systemTz.name,
};
