export const areCortexPermissionsEnabled = () =>
  isCortexEnabled() &&
  import.meta.env.VITE_CORTEX_PERMISSIONS_ENABLED === 'true';

export const isCortexEnabled = () =>
  import.meta.env.VITE_CORTEX_ENABLED === 'true';

export const isNavUpdateEnabled =
  import.meta.env.VITE_NAV_UPDATE_ENABLED === 'true';

export const isTimezoneFlagEnabled =
  import.meta.env.VITE_TIMEZONE_FLAG_ENABLED === 'true';

export const isProduction = import.meta.env.PROD;

export const isStagingEnv = import.meta.env.VITE_TARGET_ENV === 'staging';

export const isDemoEnv = import.meta.env.VITE_TARGET_ENV === 'demo';

export const isLocalDevelopment = import.meta.env.DEV;

// the variable below verifies if the given environment is the one used for unit/integration tests
export const isTestingEnv = process.env.NODE_ENV === 'test';

export const MUILicenseKey = import.meta.env.VITE_MUI_LICENSE_KEY ?? '';

function getSocketSubdomain() {
  if (isProduction && !isStagingEnv && !isDemoEnv) {
    return 'socket';
  }
  if (isDemoEnv) {
    return 'demo';
  }
  return 'socketdev';
}

export const SOCKET_URL = `wss://${getSocketSubdomain()}.thecoursekey.com/portal`;

export const BUILD_ID = import.meta.env.VITE_BUILD_ID ?? '';

export const CARDKNOX_FIELDS_KEY = import.meta.env.VITE_CARDKNOX_FIELDS_KEY;

export const CARDKNOX_SOFTWARE_NAME = import.meta.env
  .VITE_CARDKNOX_SOFTWARE_NAME;
export const CARDKNOX_SOFTWARE_VERSION = import.meta.env
  .VITE_CARDKNOX_SOFTWARE_VERSION;
export const CARDKNOX_LIB_VERSION = import.meta.env.VITE_CARDKNOX_LIB_VERSION;
