import { Box, CssBaseline } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorBoundary from 'components/routes/ErrorBoundary';
import Spinner from 'components/spinners/Spinner';
import { TimezoneProvider } from 'components/timezones';

import { MUILicenseConfig, SWRConfig } from 'config';
import { ThemeModeProvider } from 'context';
import AddPaymentMethod from 'webview/AddPaymentMethod';

const FormGeneratorWebview = lazy(() => import('./webview/FormGenerator'));
const Webapp = lazy(() => import('Webapp'));

/** This component is used to allow webview on the webapp */
const App = () => {
  return (
    <MUILicenseConfig>
      <SWRConfig>
        <ThemeModeProvider>
          <CssBaseline />
          <ErrorBoundary>
            <Suspense fallback={<div />}>
              <TimezoneProvider>
                <Routes>
                  <Route
                    element={<FormGeneratorWebview />}
                    path="/web-view/form"
                  />
                  <Route
                    element={<AddPaymentMethod />}
                    path="/web-view/addPaymentMethod"
                  />
                  <Route
                    element={
                      <Suspense
                        fallback={
                          <Box
                            height="100vh"
                            left={0}
                            position="fixed"
                            top={0}
                            width="100vw"
                          >
                            <Spinner />
                          </Box>
                        }
                      >
                        <Webapp />
                      </Suspense>
                    }
                    path="*"
                  />
                </Routes>
              </TimezoneProvider>
            </Suspense>
          </ErrorBoundary>
        </ThemeModeProvider>
      </SWRConfig>
    </MUILicenseConfig>
  );
};

export default App;
