import { axiosServerless } from 'actions';

import {
  CREATE_MERCHANT_API_URL,
  PAYMENT_CHARGE_API_URL,
  PAYMENT_METHOD_API_URL,
  PAYMENT_SCHEDULE_API_URL,
  REFUND_TRANSACTION_API_URL,
  REPORTS_API_URL,
  SEND_REMINDER,
  UPDATE_PAYMENT_PLAN_API_URL,
} from 'tools/urls/payment';

import type {
  autoPayRequest,
  autoPayRequestParams,
  DefaultPaymentMethodRequest,
  DefaultPaymentMethodRequestParams,
  deletePaymentMethodRequestParams,
  makePaymentRequest,
  PaymentMethodCreateRequest,
  PaymentMethodCreateRequestParams,
  PaymentPlanAPIRequest,
  PaymentPlanAPIRequestParams,
  PaymentPlanCancelRequest,
  PaymentPlanCancelRequestParams,
  PaymentPlanUpdateRequest,
  PaymentPlanUpdateRequestParams,
  RefundTransactionAPIRequest,
  ReportsAPIRequest,
  SendReminderRequest,
  SendReminderRequestParams,
} from 'types/payment';

/**
 * Creates a payment schedule for student.
 * @param {PaymentPlanAPIRequestParams} params - The URL of the API endpoint to which the POST request will be sent.
 * @param {PaymentPlanAPIRequest} apiData - The data to be sent in the POST request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 * @throws {Error} If there is an error during request.
 */
export const createPaymentSchedule = async (
  params: PaymentPlanAPIRequestParams,
  apiData: PaymentPlanAPIRequest,
) => {
  try {
    const apiUrl = PAYMENT_SCHEDULE_API_URL.replace('{userId}', params.userID);
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Update a payment schedule for student.
 * @param {PaymentPlanUpdateRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {PaymentPlanAPIRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 * @throws {Error} If there is an error during request.
 */
export const updatePaymentSchedule = async (
  apiParams: PaymentPlanUpdateRequestParams,
  apiData: PaymentPlanUpdateRequest,
) => {
  const apiURL = UPDATE_PAYMENT_PLAN_API_URL.replace(
    '{userId}',
    apiParams.userID.toString(),
  ).replace('{paymentPlanId}', apiParams.paymentPlanID);
  try {
    return await axiosServerless.patch(apiURL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Cancels a payment plan.
 * @param {PaymentPlanCancelRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {PaymentPlanCancelRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 * @throws {Error} If there is an error during request.
 */
export const cancelPaymentPlan = async (
  apiParams: PaymentPlanCancelRequestParams,
  apiData: PaymentPlanCancelRequest,
) => {
  try {
    const apiUrl =
      PAYMENT_SCHEDULE_API_URL.replace('{userId}', apiParams.userID) +
      '/' +
      apiParams.paymentPlanID;
    return await axiosServerless.patch(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Add a new payment method by student.
 * @param {PaymentMethodCreateRequestParams} apiParams - Add payment method url.
 * @param {PaymentMethodCreateRequest} apiData - The data to be sent in the POST request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const createPaymentMethod = async (
  apiParams: PaymentMethodCreateRequestParams,
  apiData: PaymentMethodCreateRequest,
) => {
  try {
    const apiUrl = PAYMENT_METHOD_API_URL.replace('{userId}', apiParams.userID);
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Set payment method as default.
 * @param {DefaultPaymentMethodRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {DefaultPaymentMethodRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const setDefaultPaymentMethod = async (
  apiParams: DefaultPaymentMethodRequestParams,
  apiData: DefaultPaymentMethodRequest,
) => {
  try {
    let apiUrl = PAYMENT_METHOD_API_URL.replace('{userId}', apiParams.userID);
    apiUrl = apiUrl + `/${apiParams.paymentMethodID}`;
    return await axiosServerless.patch(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Delete a payment method.
 * @param {deletePaymentMethodRequestParams} apiParams - The URL of the API endpoint to which the DELETE request will be sent.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const deletePaymentMethod = async (
  apiParams: deletePaymentMethodRequestParams,
) => {
  try {
    const apiUrl =
      PAYMENT_METHOD_API_URL.replace('{userId}', apiParams.userID) +
      `/${apiParams.paymentMethodID}`;
    return await axiosServerless.delete(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Set auto pay.
 * @param {autoPayRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {autoPayRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const setAutoPay = async (
  apiParams: autoPayRequestParams,
  apiData: autoPayRequest,
) => {
  try {
    const apiUrlAutoPay = PAYMENT_SCHEDULE_API_URL.replace(
      '{userId}',
      apiParams.userID,
    );
    const setAutoPayApiUrl = apiUrlAutoPay + `/${apiParams.paymentPlanID}`;

    return await axiosServerless.patch(setAutoPayApiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Create charge invoice transaction.
 * @param {string} apiUrl - Create charge invoice transaction api url.
 * @param {any} apiData - The data to be sent in the POST request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const chargeInvoice = async (apiUrl: string, apiData: any) => {
  try {
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Makes a payment request
 * @async
 * @param {makePaymentRequest} apiData - The data to be sent along with the payment request.
 * @returns {Promise<any>} A Promise that resolves with the response from the API after the payment request is made.
 */
export const makePayment = async (apiData: makePaymentRequest) => {
  try {
    return axiosServerless.post(PAYMENT_CHARGE_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Get transaction detail.
 * @param {string} apiUrl - Create charge invoice transaction api url.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const getTransaction = async (apiUrl: string) => {
  try {
    return await axiosServerless.get(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Create merchant.
 * @param {any} apiData - Create merchant request data.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const createMerchant = async (apiData: any) => {
  try {
    return await axiosServerless.post(CREATE_MERCHANT_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * send reminder.
 * @param {SendReminderRequestParams} apiParams - send reminder api url.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const sendReminder = async (
  apiParams: SendReminderRequestParams,
  apiData: SendReminderRequest,
) => {
  try {
    const apiUrl = SEND_REMINDER.replace('{userID}', apiParams.userID);

    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Generate report.
 * @param {string} apiUrl - Report generate api url.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const generateReport = async (apiData: ReportsAPIRequest) => {
  try {
    return await axiosServerless.post(REPORTS_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Initiates a refund for a specified transaction.
 * @param {string} transactionId - The ID of the transaction to be refunded.
 * @param {RefundTransactionAPIRequest} apiData - The data to be sent with the refund request.
 * @returns {Promise<any>} A promise that resolves with the response from the refund API.
 */
export const refundTransaction = async (
  transactionId: string,
  apiData: RefundTransactionAPIRequest,
) => {
  const apiUrl = REFUND_TRANSACTION_API_URL.replace(
    '{transactionID}',
    transactionId,
  );
  try {
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};
