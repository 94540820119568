import {
  FreeBreakfast as BreakfastIcon,
  Error as ErrorIcon,
  Flag as FlagIcon,
  HowToReg as HowToIcon,
} from '@mui/icons-material';
import { Box, SvgIconProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')({
  display: 'flex',
  justifyContent: 'center',
});

export const ModifiedIcon = (props: SvgIconProps) => (
  <StyledSpan title="Instructor or admin modified record">
    <FlagIcon color="secondary" {...props} />
  </StyledSpan>
);
export const DidNotCheckOutIcon = (props: SvgIconProps) => (
  <StyledSpan title="Student violated campus attendance policy">
    <ErrorIcon color="error" {...props} />
  </StyledSpan>
);
export const ResolvedIcon = (props: SvgIconProps) => (
  <StyledSpan title="Instructor or admin made policy exception">
    <HowToIcon color="primary" {...props} />
  </StyledSpan>
);
export const BreakIcon = (props: SvgIconProps) => (
  <StyledSpan title="Instructor checked student out for a break">
    <BreakfastIcon {...props} />
  </StyledSpan>
);

interface Props {
  autoCheckout: boolean | null;
  hasBreak?: boolean;
}

const Legend = ({ autoCheckout, hasBreak }: Props) => {
  const keys = [
    {
      icon: <ModifiedIcon fontSize="small" />,
      id: 'modified',
      show: true,
      text: 'Instructor or admin modified record',
    },
    {
      icon: <DidNotCheckOutIcon fontSize="small" />,
      id: 'no-checkout',
      show: autoCheckout === false,
      text: 'Student violated campus attendance policy',
    },
    {
      icon: <ResolvedIcon fontSize="small" />,
      id: 'resolve-checkout',
      show: autoCheckout === false,
      text: 'Instructor or admin made policy exception',
    },
    {
      icon: <BreakIcon color="inherit" fontSize="small" />,
      id: 'break',
      show: hasBreak && autoCheckout !== null,
      text: 'Instructor checked student out for a break',
    },
  ];

  return (
    <Box marginBottom={2}>
      {keys.map(
        ({ id, icon, text, show }) =>
          show && (
            <Box alignItems="center" display="flex" key={id}>
              {icon}
              <Typography display="inline" paddingLeft={2} paddingRight={2}>
                {text}
              </Typography>
            </Box>
          ),
      )}
    </Box>
  );
};

Legend.defaultProps = {
  hasBreak: false,
};

export default Legend;
