import { Info as InfoIcon } from '@mui/icons-material';
import {
  Autocomplete as AutocompleteMUI,
  AutocompleteProps,
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { forwardRef, ReactNode, useRef, useState } from 'react';

/**
 * @todo this one will be improved to be reused in the future https://app.clickup.com/t/2339756/CK-23537
 */
type ForwardedRefWithInference = <T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
) => (props: P & React.RefAttributes<T>) => React.ReactElement | null;

export interface AutocompleteSelectionRelationship {
  isAnd?: boolean;
  onRelationshipChange: (isAnd: boolean) => void;
}

interface Props<
  TValue extends number | string | null,
  TOption extends { label: string | number; value: TValue },
> {
  /** For  multiple autocomplete, remove the chips UI */
  disableChips?: boolean;
  endAdornment?: ReactNode;
  helperText?: string;
  /** The label for your input */
  label?: ReactNode;
  /**
   * The desired value of the menu min width.
   * - If `true` it will default to 300px,
   * @default false
   * */
  menuMinWidth?: true | number;
  /** Use this function to customize the option label */
  renderOptionLabel?: (option: TOption) => ReactNode;
  /** The TextField component required prop */
  required?: boolean;
  /**
   * For multiple autocomplete, the label shown when chips are disabled and there is items selected
   * @example programs -> 2 programs
   * @example students -> 2 students
   * @default 'items' -> 2 items
   * */
  selectedLabel?: string;
  /** Control the filter behavior between and/or relationship */
  selectionRelationship?: AutocompleteSelectionRelationship;
  startAdornment?: ReactNode;
  TextFieldProps?: {
    InputLabelProps?: TextFieldProps['InputLabelProps'];
  };
  useDefaultListComponent?: boolean;
}

const MAX_VISIBLE_OPTIONS = 200;

/**
 * This is the Autocomplete wrapper for the MUI auto complete. The idea is to have our design system applied to their component.
 * Right now we don't any major override so you can use their documentation.
 *
 * - [Demos](https://mui.com/material-ui/react-autocomplete/)
 * - [API](https://mui.com/material-ui/api/autocomplete/)
 */
const Autocomplete = (forwardRef as ForwardedRefWithInference)(
  <
    TValue extends number | string | null,
    TOption extends { label: string | number; value: TValue },
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
  >(
    {
      label,
      required,
      value,
      renderOptionLabel,
      disableCloseOnSelect = true,
      disableChips,
      selectedLabel,
      menuMinWidth = 300,
      selectionRelationship,
      startAdornment,
      TextFieldProps,
      helperText,
      endAdornment,
      ...autoCompleteProps
    }: Props<TValue, TOption> &
      Omit<
        AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
        'isOptionEqualToValue' | 'ListboxComponent' | 'renderInput'
      >,
    ref: React.Ref<unknown>,
  ) => {
    const isOptionsLimited = useRef(false);
    const totalOptionsCount = useRef(autoCompleteProps.options?.length ?? 0);
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);
    return (
      <AutocompleteMUI
        disableCloseOnSelect={disableCloseOnSelect}
        renderTags={disableChips ? () => null : undefined}
        {...autoCompleteProps}
        componentsProps={{
          ...autoCompleteProps.componentsProps,
          paper: {
            ...autoCompleteProps.componentsProps?.paper,
            sx: {
              ...autoCompleteProps.componentsProps?.paper?.sx,
              minWidth: { sm: menuMinWidth === true ? 300 : menuMinWidth },
              paddingX: 1,
              paddingY: 1.5,
            },
          },
        }}
        filterOptions={
          autoCompleteProps.filterOptions ??
          ((options, { inputValue, getOptionLabel }) => {
            let filteredOptions = inputValue
              ? options.filter((option) => {
                  const label = getOptionLabel(option);
                  return label.toLowerCase().includes(inputValue.toLowerCase());
                })
              : options;
            totalOptionsCount.current = filteredOptions.length;

            if (filteredOptions.length > MAX_VISIBLE_OPTIONS) {
              filteredOptions = filteredOptions.slice(0, MAX_VISIBLE_OPTIONS);
              isOptionsLimited.current = true;
            } else {
              isOptionsLimited.current = false;
            }

            return filteredOptions;
          })
        }
        isOptionEqualToValue={(option, selected) => {
          return Array.isArray(selected)
            ? selected.some((item) => item.value === option.value)
            : selected.value === option.value;
        }}
        ListboxComponent={forwardRef(
          (props, ref: React.ForwardedRef<HTMLUListElement>) => {
            return (
              <Box
                {...props}
                component="ul"
                ref={ref}
                style={{
                  margin: 0,
                }}
                sx={{
                  display: 'grid',
                  gap: 0.5,
                  gridTemplateColumns: '100%',
                }}
              >
                {selectionRelationship && (
                  <>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      pb={1}
                      pl={2}
                      pr={1}
                      pt={0.5}
                    >
                      <Typography variant="h6">
                        Filter Mode{' '}
                        <Tooltip
                          title="Multiple filter conditions under the ‘AND’ function
                            must match an item for it to appear in the results.
                            The ‘OR’ function provides a way to check for at
                            least one matching condition, as opposed to all of
                            them."
                        >
                          <IconButton aria-label="View details" size="small">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Box>
                        <ToggleButtonGroup
                          aria-label="Toggle table view"
                          onChange={(_, value: boolean) => {
                            selectionRelationship.onRelationshipChange(value);
                          }}
                          size="small"
                          value={!!selectionRelationship.isAnd}
                        >
                          <ToggleButton value={true}>AND</ToggleButton>
                          <ToggleButton value={false}>OR</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
                <Box pb={1.5} pt={selectionRelationship ? 0 : 1.5} px={1}>
                  {props.children}
                  {isOptionsLimited.current && (
                    <>
                      <Divider sx={{ my: 0.5 }} />
                      <Typography textAlign="center" variant="caption">
                        You're seeing {MAX_VISIBLE_OPTIONS} out of{' '}
                        {totalOptionsCount.current} results. Please use the
                        search to limit the results.
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            );
          },
        )}
        ref={ref}
        renderGroup={(params) => (
          <li key={params.key}>
            <Box
              maxWidth="100%"
              p={1}
              position="sticky"
              sx={{ background: theme.palette.background.paper }}
              top={-12}
              zIndex={1}
            >
              <Typography
                fontWeight={700}
                noWrap
                sx={{ fontSize: '10px' }}
                textTransform="uppercase"
                variant="h6"
              >
                {params.group}
              </Typography>
            </Box>
            <Box
              pl={1}
              sx={{
                display: 'grid',
                gap: 0.5,
                gridTemplateColumns: '100%',
              }}
            >
              {params.children}
            </Box>
          </li>
        )}
        renderInput={({ InputLabelProps, ...params }) => {
          const placeholder = ((): string => {
            if (!isFocused) {
              if (
                autoCompleteProps.multiple &&
                disableChips &&
                Array.isArray(value) &&
                value.length > 0
              )
                return `${value.length} ${selectedLabel ?? 'items'}`;

              if (autoCompleteProps.placeholder)
                return autoCompleteProps.placeholder;
            }
            return 'Search';
          })();

          return (
            <TextField
              {...params}
              defaultValue={params.inputProps?.value}
              helperText={helperText}
              InputLabelProps={
                /** the placeholder wasn't working the way it should, so while having a placeholder,
                 * InputLabelProps shouldn't be sent from the callback argument (params)
                 */
                !autoCompleteProps.placeholder
                  ? {
                      ...InputLabelProps,
                      ...TextFieldProps?.InputLabelProps,
                    }
                  : TextFieldProps?.InputLabelProps
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: endAdornment && (
                  <InputAdornment position="end">{endAdornment}</InputAdornment>
                ),
                startAdornment: startAdornment && (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                ),
              }}
              label={label}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              placeholder={placeholder}
              required={
                required && (Array.isArray(value) ? value.length === 0 : !value)
              }
              sx={{
                input: {
                  '&::placeholder': {
                    color: 'inherit',
                  },
                },
              }}
            />
          );
        }}
        renderOption={
          autoCompleteProps.renderOption ??
          ((props, item, { selected }) => {
            const itemLabel =
              typeof item === 'object' ? item.label : String(item);
            const label: ReactNode =
              renderOptionLabel?.(item) ??
              autoCompleteProps.getOptionLabel?.(item) ??
              itemLabel;

            return (
              <MenuItem
                {...props}
                key={item.value}
                selected={selected}
                style={{ padding: '8px 16px' }}
                title={`${itemLabel}`}
              >
                {autoCompleteProps.multiple && (
                  <ListItemIcon>
                    <Checkbox
                      checked={selected}
                      sx={{
                        padding: 0,
                      }}
                    />
                  </ListItemIcon>
                )}
                <span className="ellipsis">{label}</span>
              </MenuItem>
            );
          })
        }
        value={value}
      />
    );
  },
);

export default Autocomplete;
