import { DateTime } from 'luxon';

import { PaymentScheduleStatus } from 'tools/constants/payment';

import americanExpressCardImg from '../../../../media/images/payment/card/american-express.svg';
import discoverCardImg from '../../../../media/images/payment/card/discover.svg';
import masterCardCardImg from '../../../../media/images/payment/card/mastercard.svg';
import visaCardImg from '../../../../media/images/payment/card/visa.svg';

import type { PaymentSummary } from 'types/payment';

/**
 * Removes thousand separators from a string.
 * @param {string} str - The string with thousand separators.
 * @returns {string} - The string without thousand separators.
 */
export const removeThousandSeparators = (str: string) => {
  const stringWithoutCommas = str ? str.replace(/,/g, '') : '';
  return stringWithoutCommas;
};

/**
 * Adds thousand separators to a string representing a number.
 * @param {string} str - The string representing a number.
 * @returns {string} - The string with thousand separators added.
 */
export const addThousandSeparators = (str: string) => {
  return str !== '' ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};

/**
 * Get the maturity date based on the due date of the last item in the payment summary data.
 * @param {PaymentSummary} data - The payment summary data containing invoice items.
 * @returns {string} - maturity date
 */
export const getMaturityDate = (data: PaymentSummary) => {
  return convertDateIntoFormat(
    data?.PaymentSchedules?.[data.PaymentSchedules.length - 1].dueDate,
  );
};

/**
 * Get the start date based on the due date of the first item in the payment summary data.
 * @param {PaymentSummary} data - The payment summary data containing invoice items.
 * @returns {string} - start date
 */
export const getPaymentPlanStartDate = (data: PaymentSummary) => {
  return convertDateIntoFormat(data?.PaymentSchedules?.[0].dueDate);
};

/**
 * Adds currency symbol and convert into thousand separators to a string representing amount.
 * @param {string} amount - The string representing amount.
 * @returns {string} - The string with currency symbol and thousand separators added.
 */
export const convertCurrencyAmount = (amount: string) => {
  return `$${addThousandSeparators(amount)}`;
};

/**
 * Retrieves the image URL corresponding to the given card type.
 * @param {string} cardType - The type of the card (e.g., 'Visa', 'MasterCard', 'Amex').
 * @returns {string} The URL of the image corresponding to the card type. If the card type is not recognized, an empty string is returned.
 */
export const getCardImage = (cardType: string) => {
  switch (cardType) {
    case 'Visa':
      return visaCardImg;
    case 'MasterCard':
      return masterCardCardImg;
    case 'Amex':
      return americanExpressCardImg;
    case 'Discover':
      return discoverCardImg;
    default:
      return '';
  }
};

/**
 * Get the total paid balance.
 * @param {PaymentSummary} data - The payment summary data containing invoice items.
 * @returns {string} - The total paid balance.
 */
export const getCurrentBalance = (data: PaymentSummary) => {
  const paidPaymentSchedules = data.PaymentSchedules.filter(
    (schedule) => schedule.status === PaymentScheduleStatus.PAID,
  );
  const paidBalanceTillNow = paidPaymentSchedules.reduce(
    (sum, schedule) => sum + schedule.totalAmount,
    0,
  );
  return convertCurrencyAmount(paidBalanceTillNow.toString());
};

/**
 * Extracts the error message from an API response error object.
 * @param {any} error - The API response error object.
 * @returns {string} The error message extracted from the API response error object, or the error message itself if not found.
 */
export const getErrorMsgFromAPIResponse = (error: any) => {
  const errMessage = error?.error?.message || error.message;
  return errMessage;
};

/**
 * Capitalizes the first letter of a string.
 * @param {string} str - The string to capitalize.
 * @returns {string} The string with the first letter capitalized.
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Restricts input to alphabetic characters and specific control keys.
 * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event triggered by key press.
 * @returns {void}
 */
export const allowOnlyAlphabets = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const regex = /^[a-zA-Z ]$/;
  const key = event.key;

  const controlKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Home',
    'End',
  ];

  if (!regex.test(key) && !controlKeys.includes(key)) {
    event.preventDefault();
  }
};

/**
 * Restricts input to numeric characters and specific control keys.
 * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event triggered by key press.
 * @returns {void}
 */
export const allowOnlyNumeric = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const regex = /^[0-9]$/;
  const key = event.key;

  const controlKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Home',
    'End',
  ];

  if (!regex.test(key) && !controlKeys.includes(key)) {
    event.preventDefault();
  }
};

/**
 * Checks if a given date string represents a valid date.
 * @param {Date} dateString - The date string to be validated.
 * @returns {boolean} `true` if the date string is valid, `false` otherwise.
 */
export const isValidDate = (dateString: Date) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

/**
 * Formats a number into a more readable string with suffixes for thousands (K), millions (M), and billions (B).
 * @param {number} num - The number to be formatted.
 * @returns {string} The formatted number as a string with appropriate suffix.
 */
export const formatNumber = (num: number) => {
  if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(num % 1000 === 0 ? 0 : 1) + 'K';
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(num % 1000000 === 0 ? 0 : 1) + 'M';
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(num % 1000000000 === 0 ? 0 : 1) + 'B';
  }
  return num.toString();
};

export const isFirefox = () => {
  return (
    typeof navigator !== 'undefined' && /firefox/i.test(navigator.userAgent)
  );
};

/**
 * Converts a date string into a formatted date string with the format MM/DD/YYYY.
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted date string.
 */
export const convertDateIntoFormat = (date: string, format = 'MM/DD/YYYY') => {
  if (!date) return '';

  const dateObject = DateTime.fromISO(date, { zone: 'utc' });

  if (!dateObject.isValid) {
    return '';
  }

  let formattedDate;
  if (format === 'MM/DD/YYYY') {
    formattedDate = dateObject.toFormat('MM/dd/yyyy');
  } else if (format === 'Month Day, Year') {
    formattedDate = dateObject.toFormat('LLLL d, yyyy');
  }

  return formattedDate;
};

export const convertDateIntoUTCDate = (date: string) => {
  let utcDate = new Date(date);
  const timeOffsetInMinutes = utcDate.getTimezoneOffset();
  utcDate = new Date(utcDate.getTime() + timeOffsetInMinutes * 60000);
  return utcDate;
};
