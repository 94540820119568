import { BASE_URL } from '..';

const API_VERSION = 'v3';

export const CORTEX_BASE_URL = `${BASE_URL}/${API_VERSION}/cortex`;

export const WORKSPACES_BASE_URL = `${CORTEX_BASE_URL}/workspace`;

export interface WorkspaceProps {
  workspaceID: string | number;
}

export const getWorkspaceUrl = ({ workspaceID }: WorkspaceProps) =>
  `${WORKSPACES_BASE_URL}/${workspaceID}`;
