import { DateTime } from 'luxon';

import type { ISOString, Seconds } from 'tools/types';

export interface FromFormatDate {
  date: string;
  format: string;
}

export type DateType = Date | ISOString | number | DateTime | FromFormatDate;

export const ckDate = 1;

export const toDateTime = (date?: DateType) => {
  if (!date) return DateTime.now();
  if (date instanceof DateTime) return date;
  if (typeof date === 'object' && 'date' in date && 'format' in date)
    return DateTime.fromFormat(date.date, date.format);
  if (typeof date === 'number') return DateTime.fromMillis(date);
  if (typeof date === 'string') return DateTime.fromISO(date);
  if (date instanceof DateTime) return date;
  return DateTime.fromJSDate(date);
};

export const toDate = (date?: DateType) => {
  return toDateTime(date).toJSDate();
};

// exporting luxon functions to make it easier to migrate in the future
export { DateTime };

/**
 * The dayjs constructor with time zone support. Use this whenever you need time zone support
 * @todo fix type
 */
// To avoid parseISO for all dateFns methods
export const addDays = (date: DateType, amount: number): Date =>
  toDateTime(date).plus({ days: amount }).toJSDate();

export const addHours = (date: DateType, amount: number): Date =>
  toDateTime(date).plus({ hours: amount }).toJSDate();

export const addMilliseconds = (date: DateType, amount: number): Date =>
  toDateTime(date).plus({ milliseconds: amount }).toJSDate();

export const addMinutes = (date: DateType, amount: number) =>
  toDateTime(date).plus({ minutes: amount }).toJSDate();

export const addMonths = (date: DateType, amount: number): Date =>
  toDateTime(date).plus({ months: amount }).toJSDate();

export const addSeconds = (date: DateType, amount: number): Date =>
  toDateTime(date).plus({ seconds: amount }).toJSDate();

export const addWeeks = (date: DateType, amount: number): Date =>
  toDateTime(date).plus({ weeks: amount }).toJSDate();

export const differenceInHours = (
  dateLeft: DateType,
  dateRight: DateType,
): number =>
  Math.floor(toDateTime(dateLeft).diff(toDateTime(dateRight), 'hours').hours);

export const differenceInDays = (
  dateLeft: DateType,
  dateRight: DateType,
): number =>
  Math.floor(toDateTime(dateLeft).diff(toDateTime(dateRight), 'days').days);

export const differenceInMinutes = (
  dateLeft: DateType,
  dateRight: DateType,
): number =>
  Math.floor(
    toDateTime(dateLeft).diff(toDateTime(dateRight), 'minutes').minutes,
  );

export const differenceInSeconds = (
  dateLeft: DateType,
  dateRight: DateType,
): number =>
  Math.floor(
    toDateTime(dateLeft).diff(toDateTime(dateRight), 'seconds').seconds,
  );

export const differenceInMilliseconds = (
  dateLeft: DateType,
  dateRight: DateType,
): number =>
  Math.floor(
    toDateTime(dateLeft).diff(toDateTime(dateRight), 'milliseconds')
      .milliseconds,
  );

export const endOfDay = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).endOf('day').toJSDate();

export const endOfWeek = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).endOf('week').toJSDate();

export const endOfMonth = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).endOf('month').toJSDate();

/**
 * @deprecated prefer to use either the DateFormatter component or the dateFormatter function instead of this function
 */
export const formatDate = (date: DateType) =>
  dateFormatter(date, { format: PresetDateFormats.Date });

export const formatDateTime = (date: DateType) =>
  `${formatDay(date, true)}, ${formatTime(date)}`;

/**
 * @deprecated prefer to use either the DateFormatter component or the dateFormatter function instead of this function
 */
export const formatDateTimeRange = ({
  end,
  start,
}: {
  end?: DateType;
  start: DateType;
}) => {
  return `${formatDay(start)}, ${formatTimeRange({ end, start })}`;
};

/**
 * @deprecated prefer to use either the DateFormatter component or the dateFormatter function instead of this function
 */
export const formatDay = (date: DateType, short = false) =>
  dateFormatter(date, {
    format: short
      ? { custom: 'EEE, MMM d' } // example: Tue, Apr 20
      : PresetDateFormats.ShortDay,
  });

export const formatDuration = (
  secondsDuration: Seconds,
  {
    showHours = true,
    showSeconds = true,
  }: {
    showHours?: boolean;
    showSeconds?: boolean;
  } = {},
) => {
  const isNegative = secondsDuration < 0;
  const secondsDurationAbs = Math.abs(secondsDuration);

  const totalMinutes = Math.floor(secondsDurationAbs / 60);

  const minutes = totalMinutes % 60;
  const seconds = secondsDurationAbs % 60;

  let timerString = isNegative ? '-' : '';
  if (showHours) {
    const hours = Math.floor(totalMinutes / 60);
    timerString +=
      String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');

    if (showSeconds) timerString += ':' + String(seconds).padStart(2, '0');
  } else {
    timerString +=
      String(totalMinutes).padStart(2, '0') +
      ':' +
      String(seconds).padStart(2, '0');
  }

  return timerString;
};

/**
 * @deprecated prefer to use either the DateFormatter component or the dateFormatter function instead of this function
 */
export const formatTime = (date: DateType) =>
  dateFormatter(date, { format: PresetDateFormats.TimeWoSeconds });

/**
 * @todo understand all places which are using this funcions and replace it with the new ckd date
 * @deprecated use the new ckd date to format it (src\components\courses\CourseTimeRange.tsx)
 */
export const formatTimeRange = ({
  isFlex = false,
  start,
  end,
}: {
  end?: DateType | null;
  isFlex?: boolean;
  start: DateType;
}) => {
  if (isFlex) return 'Flexible Schedule';
  return `${formatTime(start)}${end ? ` - ${formatTime(end)}` : ''}`;
};

export const getTimezone = () => {
  return DateTime.local().zoneName!;
};

export const isAfter = (time1: DateType, time2: DateType) =>
  toDate(time1) > toDate(time2);

export const isAfterDay = (date1: DateType, date2: DateType) =>
  isAfter(startOfDay(date1), startOfDay(date2));

export const isBefore = (date1: DateType, date2: DateType) =>
  toDate(date1) < toDate(date2);

export const isBeforeDay = (date1: DateType, date2: DateType) =>
  isBefore(startOfDay(date1), startOfDay(date2));

export const isBetween = (date: DateType, start: DateType, end: DateType) =>
  toDate(date) >= toDate(start) && toDate(date) <= toDate(end);

export const isBetweenDay = (
  date: DateType,
  start: DateType,
  end: DateType,
) => {
  const day = startOfDay(date);
  return day >= startOfDay(start) && day <= startOfDay(end);
};

export const isSameDay = (dateLeft: DateType, dateRight: DateType): boolean =>
  toDateTime(dateLeft).hasSame(toDateTime(dateRight), 'day');

export const isSameMinute = (
  dateLeft: DateType,
  dateRight: DateType,
): boolean => toDateTime(dateLeft).hasSame(toDateTime(dateRight), 'minute');

export const isSameOrAfter = (date1: DateType, date2: DateType) =>
  toDate(date1) >= toDate(date2);

export const isSameOrBefore = (date1: DateType, date2: DateType) =>
  toDate(date1) <= toDate(date2);

export const isValid = (date?: DateType | null) =>
  !!date && toDateTime(date).isValid;

export const setTimeToDate = (date: Date | ISOString, time: Date) => {
  const newDate = new Date(date);
  newDate.setHours(
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  );
  return newDate;
};

export const startOfDay = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).startOf('day').toJSDate();

export const startOfWeek = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).startOf('week').toJSDate();

export const startOfMonth = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).startOf('month').toJSDate();

export const startOfYear = (
  date?: DateType,
  { timezone }: { timezone?: string } = {},
): Date => toDateTime(date).setZone(timezone).startOf('year').toJSDate();

export const subDays = (date: DateType, amount: number): Date =>
  toDateTime(date).minus({ days: amount }).toJSDate();

export const subMonths = (date: DateType, amount: number): Date =>
  toDateTime(date).minus({ months: amount }).toJSDate();

export const getMonth = (date: DateType): number => toDateTime(date).month;

export const getMonthDay = (date: DateType): number => toDateTime(date).day;

export enum PresetDateFormats {
  /**
   * @example '1/31/2021 (PDT)'
   */
  Date = 'M/d/yyyy',
  /**
   * @example '11:59:59 pm (PDT)'
   */
  Time = 'hh:mm:ss a',
  /**
   * @example '11:59 pm (PDT)'
   */
  TimeWoSeconds = 'hh:mm a',
  /**
   * @example '1/31/2021 11:59:59 pm (PDT)'
   */
  DateTime = 'M/d/yyyy hh:mm:ss a',
  /**
   * @example '01/31/2021 11:59 pm (PDT)'
   */
  DateTimeWoSeconds = 'M/d/yyyy hh:mm a',
  /**
   * @example 'Tuesday, April 20 (PDT)'
   */
  Day = 'EEEE, MMMM d',
  /**
   * @example 'Tuesday, Apr 20 (PDT)'
   */
  ShortDay = 'EEEE, MMM d',
  /**
   * @example 'Sep 20, 2021 (PDT)'
   */
  ShortDate = 'MMM d, yyyy',
}

export interface DateFormatterOptions {
  /**
   * The expected date format. It can be either predefined or a custom string;
   * For the available format list
   * - You can send a custom format ({@link https://moment.github.io/luxon/#/formatting?id=table-of-tokens see available formats})
   * - Or you can send a predefined format:
   * @example { custom: 'M/d/yyyy hh:mm:ss a' } -> '1/31/2021 11:59:59 pm (PDT)'
   * @example 'date' -> '1/31/2021 (PDT)'
   * @example 'time' -> '11:59:59 pm (PDT)'
   * @example 'timeWOSeconds' -> '11:59 pm (PDT)'
   * @example 'datetime' -> '1/31/2021 11:59:59 pm (PDT)'
   * @example 'datetimeWOSeconds' -> '01/31/2021 11:59 pm (PDT)'
   * @example 'day' -> 'Tue, April 20 (PDT)'
   * @example 'shortDay' -> 'Tue, Apr 20 (PDT)'
   * @example 'shortDate' -> 'Sep 20, 2021 (PDT)'
   */
  format: PresetDateFormats | { custom: string };
  /**
   * Whether to hide the timezone abbreviation or not
   * @default false
   */
  hideAbbreviation?: boolean;
  timeZone?: string;
}

export const getTimezoneAbbreviation = (timezone?: string) => {
  return DateTime.local().setZone(timezone).offsetNameShort!;
};

export const withTimezoneAbbreviation = (
  dateString: string,
  timezone?: string,
) => {
  return `${dateString} (${getTimezoneAbbreviation(timezone)})`;
};

export const dateFormatter = (
  date: DateType,
  { format, hideAbbreviation, timeZone }: DateFormatterOptions,
) => {
  const template: string = typeof format === 'object' ? format.custom : format;

  const dateString = toDateTime(date).setZone(timeZone).toFormat(template);

  return hideAbbreviation
    ? dateString
    : withTimezoneAbbreviation(dateString, timeZone);
};

export const guessSystemTimezone = () => {
  return DateTime.now().setZone('system').zoneName!;
};
