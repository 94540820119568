import { forwardRef } from 'react';

import { Select, SelectProps } from '.';

import type { BaseSchool } from 'tools/types';

interface SchoolSelectProps extends Omit<SelectProps, 'options'> {
  schools: Array<BaseSchool>;
}

const SchoolSelect = forwardRef(
  (
    { multiple = true, schools, SelectProps, sx, ...props }: SchoolSelectProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <Select
        multiple={multiple}
        ref={ref}
        {...props}
        options={schools
          .map(({ schoolID, schoolName }) => ({
            label: schoolName,
            value: schoolID,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        renderLabel="Schools"
      />
    );
  },
);

export default SchoolSelect;
