import { memo } from 'react';
import useSWR, { type Key, type SWRConfiguration } from 'swr';

import Spinner from 'components/spinners/Spinner';

import ErrorView from './views/ErrorView';

type Children = (data: any) => React.ReactElement;

export interface FetcherProps {
  api: Key;
  children?: Children;
  loader?: React.ReactElement | null;
  options?: SWRConfiguration;
  showRetry?: boolean;
  showValidating?: boolean;
}

/**
 * @todo [Add Fetcher unit tests](https://app.clickup.com/t/3c5y61a)
 */
export const Fetcher = ({
  api,
  children = () => <div />,
  loader = <Spinner />,
  options,
  showRetry = true,
  showValidating = false,
}: FetcherProps): React.ReactElement | null => {
  const { data, error, mutate, isValidating } = useSWR(api, options);

  if (api !== null && !error) {
    if (!data || (showValidating && isValidating)) {
      return loader;
    }
  }

  if (error)
    return (
      <ErrorView
        onRetry={() => mutate()}
        showRetry={showRetry && !isValidating}
        title={error!.message}
      />
    );

  return <MemoChildren data={data}>{children}</MemoChildren>;
};

const ChildrenWrapper = ({
  children,
  data,
}: {
  children: Children;
  data: any;
}) => children(data);

const MemoChildren = memo(ChildrenWrapper);

export default Fetcher;
