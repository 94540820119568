import axios, { AxiosError, AxiosResponse } from 'axios';

import { clearSession, logout } from 'actions/loginActions';

import { isDemoEnv, isProduction, isStagingEnv } from 'helpers/env';

export enum ErrorStatus {
  AccessDenied = 401,
  FailedPreCondition = 412,
  Forbidden = 403,
}

const NETWORK_TIME_THRESHOLD = 60000; // 60 seconds

// —————————————————————————————————————————————————————————————————————————————————————————————————
// Axios Defaults
// —————————————————————————————————————————————————————————————————————————————————————————————————
const axiosInstance = axios.create({
  baseURL: window.location.origin,
  headers: {
    'Content-Type': 'application/json',
    nimbus: 'goku',
    // for forced updates
    powerLevel: '77',
    'X-teste': 22,
  },
  timeout: NETWORK_TIME_THRESHOLD,
});

const serverlessEnv = isProduction && !isStagingEnv && !isDemoEnv ? '' : '-dev';
const axiosServerless = axios.create({
  baseURL: `https://services${serverlessEnv}.thecoursekey.com`,
  withCredentials: true,
});

// —————————————————————————————————————————————————————————————————————————————————————————————————
// Request Interceptor
// —————————————————————————————————————————————————————————————————————————————————————————————————
// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    if (config?.headers && window.timezone) {
      config.headers['X-Timezone'] = window.timezone;
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  },
);

// —————————————————————————————————————————————————————————————————————————————————————————————————
// Response Interceptor
// —————————————————————————————————————————————————————————————————————————————————————————————————
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<{ error: { code?: number; message: string } }>) => {
    // determine if on login page
    const pathname = window.location.pathname || '';
    const onLoginPage = ['/register', '/login'].some((route) =>
      pathname.includes(route),
    );

    // TODO: Auto-show snackbar unless passed flag to not show it
    let status = 0;
    if (error.response) status = error.response.status;
    else {
      // @ts-ignore
      error.response = {
        data: {
          error: {
            message:
              'The server encountered an error and could not complete your request.',
          },
        },
      };
    }

    // e.g. power level mismatch
    if (status === ErrorStatus.FailedPreCondition) {
      window.location.reload();
      // forbidden access
    } else if (
      status === ErrorStatus.AccessDenied ||
      status === ErrorStatus.Forbidden
    ) {
      if (!onLoginPage) {
        logout();
      } else {
        clearSession();
      }
    }

    return Promise.reject(error);
  },
);

axiosServerless.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // determine if on login page
    const pathname = window.location.pathname || '';
    const onLoginPage = ['/register', '/login'].some((route) =>
      pathname.includes(route),
    );

    // TODO: Auto-show snackbar unless passed flag to not show it
    let status = 0;
    if (error.response) status = error.response.status;
    else {
      // @ts-ignore
      error.response = {
        data: 'Network Error',
      };
    }
    // e.g. power level mismatch
    if (status === ErrorStatus.FailedPreCondition) {
      window.location.reload();
      // forbidden access
    } else if (
      status === ErrorStatus.AccessDenied ||
      status === ErrorStatus.Forbidden
    ) {
      if (!onLoginPage) {
        logout();
      } else {
        clearSession();
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
export { axiosServerless };
