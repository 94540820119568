import { Schedule as ScheduleIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  type TextFieldProps,
  Typography,
} from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { startOfDay, toDateTime } from 'helpers/datetime';

import { useTimezoneContext } from 'components/timezones';

import getTimePlaceholder from './getTimePlaceholder';

const HOURS_INTERVAL = 15;

function formatTime(date?: Date | null) {
  return date ? toDateTime(date).toFormat('HH:mm a')!.toLowerCase() : '';
}

type Props = Omit<
  TextFieldProps,
  'onBlur' | 'onChange' | 'placeholder' | 'value'
> & {
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    nextDate: Date | null,
  ) => void;
  onChange?: (date: Date | null) => void;
  value?: Date | null;
};

const TimeField = forwardRef(
  ({ value: controlledValue, onChange, ...props }: Props, ref) => {
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const { currentTimeZoneAbbreviation } = useTimezoneContext();

    const options = (() => {
      const baseDate = startOfDay();

      return new Array(96).fill(undefined).map((_, index) => {
        const date: Date = new Date(baseDate);
        date.setMinutes(index * HOURS_INTERVAL);
        return formatTime(date);
      });
    })();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [value, setValue] = useState<string>(formatTime(controlledValue));

    const fallback = useRef<string>();
    const hasError = value !== '' && !getTimePlaceholder(value);

    useEffect(() => {
      setValue(formatTime(controlledValue));
    }, [controlledValue]);

    const handleChange = (value: Date | null) => {
      onChange?.(value);
    };

    const handleInternalChange = (value: string): Date | null => {
      const date =
        getTimePlaceholder(value) ?? getTimePlaceholder(fallback.current ?? '');

      setValue(formatTime(date));
      handleChange(date);
      return date;
    };

    return (
      <>
        <TextField
          {...props}
          error={hasError || props.error}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <Box alignItems="center" display="flex" gap={0.5}>
                <Typography
                  fontStyle="italic"
                  variant="caption"
                  whiteSpace="nowrap"
                >
                  {currentTimeZoneAbbreviation}
                </Typography>
                <IconButton
                  onClick={() => setIsMenuOpen(true)}
                  size={props.size}
                  tabIndex={-1}
                >
                  <ScheduleIcon fontSize={props.size} />
                </IconButton>
              </Box>
            ),
            ref,
            style: {
              ...props.InputProps?.style,
              paddingRight: 'unset',
            },
          }}
          onBlur={(e) => {
            const next = handleInternalChange(value);
            props.onBlur?.(e, next);
          }}
          onChange={(e) => setValue(e.target.value ?? '')}
          onFocus={(e) => {
            e.target.select();
            fallback.current = value;
            props.onFocus?.(e);
          }}
          placeholder="hh:mm (a|p)m"
          ref={anchorRef}
          value={value}
        />
        <Menu
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          onClose={() => setIsMenuOpen(false)}
          open={isMenuOpen}
          PaperProps={{ sx: { width: '200px' } }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          {options.map((value) => (
            <MenuItem
              key={value}
              onClick={() => {
                handleInternalChange(value);
                setIsMenuOpen(false);
              }}
            >
              {value}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);

export default TimeField;
