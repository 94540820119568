import { CORTEX_BASE_URL, getWorkspaceUrl, type WorkspaceProps } from '..';

interface AutomationProps extends WorkspaceProps {
  automationID: string | number;
}

export const getAutomationStatusUrl = (props: AutomationProps) =>
  `${getAutomationUrl(props)}/status`;

export const getAutomationUrl = ({ automationID, ...props }: AutomationProps) =>
  `${getAutomationsUrl(props)}/${automationID}`;

export const getAutomationCopyUrl = ({
  automationID,
}: {
  automationID: string | number;
}) => `${CORTEX_BASE_URL}/automation/copy/${automationID}`;

export const getAutomationsUrl = (props: WorkspaceProps) =>
  `${getWorkspaceUrl(props)}/automation`;

export const getAutomationsActivityExportUrl = (props: WorkspaceProps) =>
  `${getAutomationsActivityUrl(props)}/export`;

export const getAutomationsActivityUrl = (props: WorkspaceProps) =>
  `${getAutomationsUrl(props)}/activity`;

export const getAutomationsMetadataUrl = (props: WorkspaceProps) =>
  `${getAutomationsUrl(props)}/metadata`;

export const getAutomationsSettingsUrl = (props: WorkspaceProps) =>
  `${getAutomationsUrl(props)}/settings`;

export const getAutomationsSummaryUrl = (props: WorkspaceProps) =>
  `${getAutomationsUrl(props)}/summary`;

export const getAutomationsListUrl = (props: WorkspaceProps) =>
  `${getAutomationsUrl(props)}/page`;
