export const getCourseUrl = ({
  courseID,
  version = 'v4',
}: { courseID?: string | number; version?: string } = {}) =>
  `/api/${version}/course${courseID ? `/${courseID}` : ''}`;

export const updateBreakCourseUrl = ({
  courseID,
}: { courseID?: string | number } = {}) =>
  `/api/v3/attendance/professor/${courseID}/settings`;
