import { useProfile } from 'hooks';

/** Return all school that admins has userGroups access */
export const useUserGroupsAvailableSchools = ({
  editOnly = false,
}: {
  /**
   * True if you want to only show schools that the user has edit access to
   * @default false
   */
  editOnly?: boolean;
} = {}) => {
  const { schoolPermissions = [] } = useProfile();

  return schoolPermissions.filter((schoolPermission) => {
    return editOnly
      ? schoolPermission.editUser
      : schoolPermission.viewStudentManagement || schoolPermission.editUser;
  });
};
