import { ReactNode, useCallback, useMemo } from 'react';

import { WorkspaceType } from 'tools/constants/workspaces';

import { createRequiredContext } from 'helpers/createRequiredContext';
import { isCortexEnabled, isTestingEnv } from 'helpers/env';

import { useProfile } from 'hooks/api';
import { UserPermission, UserPermissions, UserTypes } from 'types/accessRules';

import type {
  CheckAccessPermission,
  CheckSchoolsPermission,
  CheckUserPermissions,
  CheckUserType,
  CheckWorkspaceAccess,
} from './types';
import type { SchoolPermission } from 'tools/types/profile';
import type { Workspace } from 'tools/types/workspaces';

const defaultMatchMethod = 'every';

function toArray<T>(item: T | T[]): T[] {
  return Array.isArray(item) ? item : [item];
}

export interface AccessRulesContextType {
  /**
   * User this function to check if match different types of permission
   */
  checkAccessPermission: CheckAccessPermission;
  /**
   * Use this function to check user access through school permissions
   */
  checkSchoolsPermissions: CheckSchoolsPermission;
  /**
   * Use this function to check user access through his profile permissions
   */
  checkUserPermissions: CheckUserPermissions;
  /**
   * Use this function to check if the user type match some the desired user types.
   */
  checkUserType: CheckUserType;
  /**
   * Use this function to check if the user has access to one or many workspaces.
   */
  checkWorkspaceAccess: CheckWorkspaceAccess;
  /** contains all compliance workspace */
  complianceWorkspaces: Workspace[];
  schoolsPermissions: SchoolPermission[];
  userPermissions: UserPermissions;
  userType: UserTypes;
  /** contains all non compliance workspace */
  workspaces: Workspace[];
}

export const [AccessRulesContext, useAccessRulesContext] =
  createRequiredContext<AccessRulesContextType>();

interface Props {
  children: ReactNode;
  /** @deprecated this property is only used for testing purposes */
  testUserPermissions?: UserPermissions;
}

export const AccessRulesProvider = ({
  children,
  testUserPermissions,
}: Props) => {
  if (!isTestingEnv && testUserPermissions) {
    throw new TypeError(
      'You should only use this property on testing environment',
    );
  }

  const profile = useProfile();

  // HANDLE USER INFO
  const {
    userType,
    userPermissions,
    schoolsPermissions,
    workspaces,
    complianceWorkspaces,
  } = useMemo((): {
    complianceWorkspaces: Workspace[];
    schoolsPermissions: SchoolPermission[];
    userPermissions: UserPermissions;
    userType: UserTypes;
    workspaces: Workspace[];
  } => {
    const { isSupport, isSchoolAdmin, isTeacher, cortex, schoolPermissions } =
      profile;

    // USER TYPE RULES
    const userType = ((): UserTypes => {
      if (isSupport) return UserTypes.Support;
      if (isSchoolAdmin) return UserTypes.Administrator;
      if (isTeacher) return UserTypes.Staff;
      return UserTypes.Student;
    })();

    // SCHOOL PERMISSIONS RULES
    const schoolsPermissions = schoolPermissions ?? [];

    // WORKSPACES
    /** We are separating the workspaces since product needs to be separated on the UI. But the BE keeps having the same structure. */
    const workspaces =
      cortex?.workspaces.filter(
        ({ workspaceType }) => workspaceType !== WorkspaceType.Compliance,
      ) ?? [];

    const complianceWorkspaces =
      cortex?.workspaces.filter(
        ({ workspaceType }) => workspaceType === WorkspaceType.Compliance,
      ) ?? [];

    // USER PERMISSIONS

    const userPermissions: UserPermissions = testUserPermissions ?? {
      [UserPermission.HasAgreedToTerms]: profile.hasAgreedToTerms,
      /** @todo check VITE_CORTEX_PERMISSIONS_ENABLED flag */
      [UserPermission.HasCortex]: isCortexEnabled() && !!cortex,
      [UserPermission.HasLicense]: profile.hasLicense,
      [UserPermission.HasNotification]: profile.hasNotification,
      [UserPermission.HasPrograms]: profile.hasPrograms,
      [UserPermission.HasRetentionDashboard]: profile.hasRetentionDashboard,
      [UserPermission.HasManagedContent]: profile.managedContent,
      [UserPermission.HasComplianceWorkspace]:
        isCortexEnabled() && complianceWorkspaces.length > 0,
      [UserPermission.HasWorkspaces]:
        isCortexEnabled() && !!cortex && cortex.workspaces.length > 0,
      [UserPermission.HasRotationManagement]:
        profile.rotationManagement?.enabled ?? false,
      [UserPermission.IsSchoolAdmin]: profile.isSchoolAdmin,
      [UserPermission.IsSupport]: profile.isSupport,
      [UserPermission.IsTeacher]: profile.isTeacher,
    };

    return {
      complianceWorkspaces,
      schoolsPermissions,
      userPermissions,
      userType,
      workspaces,
    };
  }, [profile, testUserPermissions]);

  // CHECK FUNCTIONS

  const checkUserType = useCallback<CheckUserType>(
    (userTypes) =>
      toArray(userTypes).some((userTypeParam) => userTypeParam === userType),
    [userType],
  );

  const checkUserPermissions = useCallback<CheckUserPermissions>(
    (rules) =>
      toArray(rules).some(({ permissions, method = defaultMatchMethod }) => {
        if (Array.isArray(permissions) || typeof permissions === 'string')
          return toArray(permissions)[method](
            (permission) => userPermissions[permission],
          );
        return Object.entries(permissions)[method](
          ([permission, expected]) =>
            userPermissions[permission as UserPermission] === expected,
        );
      }),
    [userPermissions],
  );

  const checkSchoolsPermissions = useCallback<CheckSchoolsPermission>(
    (rules) =>
      toArray(rules).some(
        ({
          permissions,
          matchPermissionMethod = defaultMatchMethod,
          matchSchoolsMethod = defaultMatchMethod,
          schoolIDs,
        }) => {
          const schools = schoolIDs
            ? schoolsPermissions.filter(({ schoolID }) =>
                schoolIDs.includes(schoolID),
              )
            : schoolsPermissions;
          return (
            schools.length !== 0 &&
            schools[matchSchoolsMethod]((school) =>
              toArray(permissions)[matchPermissionMethod](
                (permission) => school[permission],
              ),
            )
          );
        },
      ),
    [schoolsPermissions],
  );

  const checkWorkspaceAccess = useCallback<CheckWorkspaceAccess>(
    (rules) =>
      toArray(rules).some(
        ({ workspacesIDs, matchMethod = defaultMatchMethod }) =>
          toArray(workspacesIDs)[matchMethod]((workspaceID) =>
            workspaces.some(
              (workspace) => workspaceID === workspace.workspaceID,
            ),
          ),
      ),
    [workspaces],
  );

  const checkAccessPermission = useCallback<CheckAccessPermission>(
    (rules, { matchMethod = 'some' } = {}) =>
      toArray(rules)[matchMethod](
        ({ userTypes, user, schools, workspaces, shouldHave = true }) => {
          const matchUserType = !userTypes || checkUserType(userTypes);
          const matchUserPermission = !user || checkUserPermissions(user);
          const matchSchoolPermission =
            !schools || checkSchoolsPermissions(schools);
          const matchWorkspaces =
            !workspaces || checkWorkspaceAccess(workspaces);
          const hasPermission =
            matchUserType &&
            matchUserPermission &&
            matchSchoolPermission &&
            matchWorkspaces;
          return shouldHave === hasPermission;
        },
      ),
    [
      checkUserType,
      checkUserPermissions,
      checkSchoolsPermissions,
      checkWorkspaceAccess,
    ],
  );

  const providerValue = useMemo(
    (): AccessRulesContextType => ({
      checkAccessPermission,
      checkSchoolsPermissions,
      checkUserPermissions,
      checkUserType,
      checkWorkspaceAccess,
      complianceWorkspaces,
      schoolsPermissions,
      userPermissions,
      userType,
      workspaces,
    }),
    [
      checkAccessPermission,
      checkSchoolsPermissions,
      checkUserPermissions,
      checkUserType,
      checkWorkspaceAccess,
      schoolsPermissions,
      userPermissions,
      userType,
      workspaces,
      complianceWorkspaces,
    ],
  );
  return (
    <AccessRulesContext.Provider value={providerValue}>
      {children}
    </AccessRulesContext.Provider>
  );
};

export default AccessRulesProvider;
