import { getTimezone, toDateTime } from 'helpers/datetime';

function getTime(value: string): Record<'hours' | 'minutes', string | null> {
  const numbers = value?.replace(/\D+/g, '');
  const valueNumberWithDoubleDots = value.replace(/[^0-9:]/g, '');
  let hours = null;
  let minutes = null;
  switch (true) {
    case numbers.length === 0 || numbers.length > 4:
      return {
        hours: null,
        minutes: null,
      };
    case numbers.length < 2 ||
      (numbers.length === 2 && valueNumberWithDoubleDots.indexOf(':') !== 1):
      return {
        hours: numbers,
        minutes: null,
      };
    case numbers.length === 2:
      [hours, minutes] = numbers.split('');
      return {
        hours: hours,
        minutes: `${minutes}0`,
      };
    case numbers.length === 3 && valueNumberWithDoubleDots.indexOf(':') === 2:
      hours = numbers.slice(0, 2);
      minutes = `${numbers.slice(2, 3)}0`;
      return {
        hours: hours,
        minutes: minutes,
      };
    default: {
      const base = numbers.length === 3 ? `0${numbers}` : numbers;
      hours = base.slice(0, 2);
      minutes = base.slice(-2);
      return {
        hours: hours,
        minutes: minutes,
      };
    }
  }
}

interface GetTimePlaceholderOptions {
  baseDate?: Date;
  timezone?: string;
}

export default function getTimePlaceholder(
  value: string,
  {
    baseDate = new Date(),
    timezone = getTimezone(),
  }: GetTimePlaceholderOptions = {},
): Date | null {
  const { hours, minutes } = getTime(value);
  if (!hours || Number(hours) > 24 || (minutes && Number(minutes) > 60))
    return null;
  const dateFormat = /a/i.test(value) ? 'am' : 'pm';

  const convertHours = (
    hours: number | string,
    format: 'am' | 'pm' = dateFormat,
  ) => {
    if (typeof hours === 'string') hours = Number(hours);
    if (format === 'am' && hours === 12) return 0;
    return format === 'pm' && hours < 12 ? hours + 12 : hours;
  };

  // clearing the date

  const dateTime = toDateTime(new Date(baseDate))
    .setZone(timezone)
    .set({
      hour: convertHours(hours),
      millisecond: 0,
      minute: Number(minutes ?? 0),
      second: 0,
    });

  return dateTime.toJSDate();
}
