import { Circle as CircleIcon } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  TablePagination as MuiTablePagination,
  Paper,
  PaperProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import Papa from 'papaparse';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import {
  CellProps,
  Column,
  TableInstance as DataTableInstance,
  TableState as DataTableState,
  HeaderGroup,
  Row,
  SortingRule,
  useColumnOrder,
  UseColumnOrderInstanceProps,
  UseColumnOrderState,
  useFilters,
  UseFiltersColumnOptions,
  UseFiltersInstanceProps,
  UseFiltersState,
  useGlobalFilter,
  UseGlobalFiltersColumnOptions,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useRowSelect,
  UseRowSelectInstanceProps,
  useRowState,
  UseRowStateCellProps,
  UseRowStateInstanceProps,
  UseRowStateRowProps,
  useSortBy,
  UseSortByColumnOptions,
  UseSortByInstanceProps,
  UseSortByState,
  useTable,
} from 'react-table';
// @ts-ignore
import { useExportData } from 'react-table-plugins';
import { useUpdateEffect } from 'react-use';

import { useIsLightMode } from 'hooks';
import { gray1, gray2, gray9, topBarDark } from 'styles/colors';

import {
  DEFAULT_TABLE_PAGE_SIZE,
  DEFAULT_TABLE_ROWS_PER_PAGE,
  TablePagination,
} from '.';

import type { ReactNode } from 'react';

export type TableColumn<D extends object = {}> = Column<D> &
  UseFiltersColumnOptions<D> &
  UseGlobalFiltersColumnOptions<D> &
  UseSortByColumnOptions<D> & {
    align?: 'center' | 'left' | 'right';
    className?: string;
    disableExport?: boolean;
    getCellExportValue?: (
      row: Row<any>,
      column?: Column<any>,
    ) => number | string;
    getColumnExportValue?: (column: Column<D>) => string;
    padding?: string;
    style?: React.CSSProperties;
  };

export type Cell<
  D extends object = {},
  V = any,
  S extends object = {},
> = CellProps<D, V> & {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging: boolean;
  row: UseRowStateCellProps<S> & { state: S };
};

export interface DataTableProps extends Omit<TableProps, 'onDragEnd'> {
  colgroup?: ReactNode;
  columns: any;
  currentPage?: number;
  data: Array<{ [key: string]: any }>;
  disableCheckbox?: (row: any) => boolean | string;
  elevation?: number;
  getExportFileName?: ({
    fileType,
    all,
  }: {
    all: boolean;
    fileType: string;
  }) => string;
  getRowProps?: (row: Row<any>) => Omit<TableRowProps, 'key'>;
  initialColumnOrder?: Array<string>;
  initialFilters?: Array<{ id: string; value: any }>;
  initialGlobalFilter?: string | null;
  initialHiddenColumns?: Array<string>;
  initialPageSize?: number;
  initialRowStateAccessor?: (row: any) => any;
  initialSelectedRows?: Object;
  initialSortBy?: Array<{ desc: boolean; id: string }>;
  isRowSelectable?: (row: any) => boolean;
  manualSortBy?: boolean;
  onDragEnd?: (srcIndex: number, dstIndex: number) => void;
  onInstanceChange?: (instance: TableInstance | null) => void;
  onManualPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void | undefined;
  onManualRowsPerPageChange?: (rowsPerPage: number) => void;
  onRowSelectChange?: (selected: Array<any>) => void;
  onSortChange?: (sort: SortingRule<any>[]) => void;
  pagination?: TablePagination;
  paperProps?: PaperProps;
  ref?: any;
  rowsPerPage?: number;
  rowsPerPageOptions?: Array<number>;
  selectable?: boolean;
  selectedResumeAction?: ReactNode;
  selectedResumeLabel?: string;
  showEmpty?: boolean;
  showFooter?: boolean;
  showSelectedResume?: boolean;
  toggleAllRowsSelectedProps?:
    | {
        onChange?: Function;
      }
    | object;
  totalItems?: number;
  variant?: 'elevation' | 'outlined';
}

export type TableState<D extends object = {}> = DataTableState<D> &
  UseColumnOrderState<D> &
  UseFiltersState<D> &
  UseGlobalFiltersState<D> &
  UsePaginationState<D> &
  UseSortByState<D>;

export type TableInstance<D extends object = {}> = Omit<
  DataTableInstance<D>,
  'rows'
> & {
  rows: Array<Row<D> & UseRowStateRowProps<D>>;
} & UseFiltersInstanceProps<D> &
  UseColumnOrderInstanceProps<D> &
  UseGlobalFiltersInstanceProps<D> &
  UsePaginationInstanceProps<D> &
  UseRowSelectInstanceProps<D> &
  UseRowStateInstanceProps<D> &
  UseSortByInstanceProps<D> & {
    exportData: (fileType: string, all: boolean) => void;
    state: TableState<any>;
  };

export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';

export const IndeterminateCheckbox = forwardRef(
  (
    {
      indeterminate,
      tooltip,
      ...rest
    }: { indeterminate: number; tooltip?: string },
    ref?: React.Ref<any>,
  ) => {
    const checkbox = (
      <Checkbox indeterminate={Boolean(indeterminate)} ref={ref} {...rest} />
    );

    return (
      <>
        {tooltip ? (
          <Tooltip title={tooltip}>
            <span>{checkbox}</span>
          </Tooltip>
        ) : (
          checkbox
        )}
      </>
    );
  },
);

/**
 * If `pagination="manual"`, please make use of the `useManualTablePagination()` hook.
 */
const DataTable = forwardRef(
  (
    {
      colgroup = null,
      columns: tableColumns,
      currentPage = 0,
      data,
      disableCheckbox = () => false,
      elevation,
      getExportFileName = undefined,
      getRowProps = () => ({}),
      initialColumnOrder = [],
      initialFilters = [],
      initialRowStateAccessor = undefined,
      initialGlobalFilter = '',
      initialHiddenColumns = [],
      initialPageSize = DEFAULT_TABLE_PAGE_SIZE,
      initialSelectedRows = {},
      initialSortBy = [],
      isRowSelectable = () => true,
      onDragEnd,
      onInstanceChange = () => {},
      onRowSelectChange = () => {},
      pagination,
      paperProps,
      rowsPerPage,
      rowsPerPageOptions = DEFAULT_TABLE_ROWS_PER_PAGE,
      selectable = false,
      onManualPageChange = () => {},
      onManualRowsPerPageChange = () => {},
      showFooter = false,
      toggleAllRowsSelectedProps = {},
      totalItems = 0,
      showSelectedResume,
      selectedResumeLabel = 'Selected',
      selectedResumeAction,
      variant,
      manualSortBy,
      onSortChange,
      ...rest
    }: DataTableProps,
    ref,
  ) => {
    const isLight = useIsLightMode();

    const getExportFileBlob = ({
      columns,
      data,
      fileType,
    }: {
      columns: Array<Column<any>>;
      data: Array<any>;
      fileName: string;
      fileType: string;
    }) => {
      if (fileType === 'csv') {
        // @ts-ignore
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ data, fields: headerNames });
        return new Blob([csvString], { type: 'text/csv' });
      }
    };

    const hasManualPagination = pagination === TablePagination.Manual;

    const instance = useTable(
      {
        // reset options
        autoResetFilters: false,
        autoResetGlobalFilter: false,
        autoResetPage: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,

        // core options
        columns: tableColumns,
        data,

        // sort options
        disableSortRemove: true,
        getExportFileBlob,
        getExportFileName,
        initialRowStateAccessor,
        initialState: {
          // @ts-ignore
          columnOrder: initialColumnOrder,
          filters: initialFilters,
          globalFilter: initialGlobalFilter,
          hiddenColumns: initialHiddenColumns,
          pageSize: initialPageSize,
          selectedRowIds: initialSelectedRows,
          sortBy: initialSortBy,
        },
        // pagination options
        manualPagination: pagination !== TablePagination.Automatic,

        manualSortBy,

        // TODO: wait for react-table to have better support for selecting multiple rows
        stateReducer: (
          newState: { [key: string]: any },
          action: { [key: string]: any; type: string },
          prevState: { [key: string]: any },
        ) => {
          switch (action.type) {
            case SET_SELECTED_ROWS:
              return { ...prevState, selectedRowIds: action.selectedIds };
            default:
              return newState;
          }
        },
      },
      useColumnOrder,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      useRowState,
      useExportData,
      (hooks: { [key: string]: any }) => {
        // add checkbox select column
        if (selectable) {
          hooks.allColumns.push((columns: any) => [
            {
              Cell: (cell: any) => {
                if (!isRowSelectable(cell.row.original)) return null;

                // TODO: remove when fix comes for row + global filter issue
                const onChange = (
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  const selectedIds = { ...cell.state.selectedRowIds };
                  const isSelected = event.target.checked;
                  if (!isSelected) {
                    delete selectedIds[cell.row.id];
                  } else {
                    selectedIds[cell.row.id] = true;
                  }
                  cell.dispatch({ selectedIds, type: SET_SELECTED_ROWS });
                };
                const disabled = disableCheckbox(cell.row);
                const tooltip = typeof disabled === 'string' ? disabled : '';
                return (
                  <IndeterminateCheckbox
                    disabled={!!disabled}
                    tooltip={tooltip}
                    {...cell.row.getToggleRowSelectedProps()}
                    onChange={onChange}
                    title=""
                  />
                );
              },
              disableSortBy: true,
              Header: ({ getToggleAllRowsSelectedProps }: any) => {
                if (!rows.map((row) => row.original).find(isRowSelectable))
                  return null;

                return (
                  <IndeterminateCheckbox
                    disabled={disableCheckbox(null)}
                    {...getToggleAllRowsSelectedProps(
                      toggleAllRowsSelectedProps,
                    )}
                    title=""
                  />
                );
              },
              id: 'selection',
              padding: 'none',
              style: {
                width: '40px',
              },
            },
            ...columns,
          ]);
        }
      },
    ) as TableInstance<any>;

    const {
      allColumnsHidden,
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      footerGroups, // footerGroups if your table have groupings
      headerGroups, // headerGroups if your table have groupings
      rows, // rows for the table based on the data passed
      prepareRow, // Prepare the row (this function need to called for each row before getting the row props)
      // pagination info
      page,
      setPageSize,
      gotoPage,

      // select info
      selectedFlatRows,

      state: { filters, globalFilter, pageIndex, pageSize, sortBy },
    } = instance;

    const onSortChangeRef = useRef(onSortChange);
    useEffect(() => {
      onSortChangeRef.current = onSortChange;
    }, [onSortChange]);

    useUpdateEffect(() => {
      onSortChangeRef.current?.(sortBy);
    }, [sortBy]);
    const onRowSelectChangeRef = useRef(onRowSelectChange);
    useEffect(() => {
      onRowSelectChangeRef.current = onRowSelectChange;
    }, [onRowSelectChange]);

    useEffect(() => {
      if (selectable) {
        onRowSelectChangeRef.current(
          selectedFlatRows.map((row: Row) => row.original),
        );
      }
    }, [selectable, selectedFlatRows]);

    useEffect(() => {
      onInstanceChange(instance);
      return () => {
        onInstanceChange(null);
      };
    }, [instance, onInstanceChange]);

    useEffect(() => {
      gotoPage(0);
    }, [filters, globalFilter, gotoPage]);

    useEffect(() => {
      if (instance.state.pageIndex + 1 > instance.pageCount) {
        gotoPage(0);
      }
    }, [gotoPage, instance.pageCount, instance.state.pageIndex]);

    // return table instance
    useImperativeHandle(ref, () => instance);

    // pagination
    const handleChangePage = (event: any, newPage: number) => {
      gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
      setPageSize(parseInt(event.target.value, 10));
    };

    useEffect(() => {
      if (rowsPerPage) setPageSize(rowsPerPage);
    }, [rowsPerPage, setPageSize]);

    const parseRowsPerPage =
      (callback: (rowsPerPage: number) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        callback(parseInt(event.target.value, 10));
      };

    const paginationProps = hasManualPagination
      ? {
          count: totalItems,
          onPageChange: onManualPageChange,
          onRowsPerPageChange: parseRowsPerPage(onManualRowsPerPageChange),
          page: currentPage,
        }
      : {
          count: rows?.length,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
          page: pageIndex + 1 > instance.pageCount ? 0 : pageIndex,
        };

    // row drag and drop
    const isDraggable = Boolean(onDragEnd);
    return (
      <Paper
        elevation={elevation}
        sx={{
          overflow: 'hidden',
        }}
        variant={variant}
        {...paperProps}
      >
        <TableContainer>
          <DragDropWrapper onDragEnd={onDragEnd}>
            {(provided) => (
              <Table
                {...getTableProps()}
                ref={provided?.innerRef}
                stickyHeader
                {...rest}
              >
                {colgroup}
                <TableHead>
                  {headerGroups.map((headerGroup: HeaderGroup) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => {
                          let ariaSort = 'none';
                          if (column.isSorted) {
                            ariaSort = column.isSortedDesc
                              ? 'descending'
                              : 'ascending';
                          }
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <TableCell
                              align={column.align}
                              aria-sort={ariaSort}
                              {...column.getHeaderProps([
                                {
                                  className: column.className,
                                  style: column.style,
                                },
                              ])}
                              padding={column.padding}
                            >
                              {/* TODO: https://app.clickup.com/t/2339756/CK-20325 */}
                              {hasManualPagination && !manualSortBy ? (
                                column.render('Header')
                              ) : (
                                <TableSortLabel
                                  active={column.isSorted}
                                  direction={
                                    column.isSortedDesc ? 'desc' : 'asc'
                                  }
                                  hideSortIcon={column.disableSortBy}
                                  {...column.getSortByToggleProps()}
                                  title=""
                                >
                                  {column.render('Header')}
                                  {column.canFilter && column.Filter
                                    ? column.render('Filter')
                                    : null}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {showSelectedResume && selectedFlatRows.length > 0 && (
                    <TableRow>
                      <TableCell
                        sx={{
                          background: isLight ? gray2 : topBarDark,
                          py: 1,
                        }}
                      />
                      <TableCell
                        colSpan={100}
                        sx={{
                          background: isLight ? gray2 : topBarDark,
                          py: 1,
                        }}
                      >
                        <Box alignItems="center" display="flex">
                          <Box alignItems="center" display="flex">
                            <CircleIcon
                              color="primary"
                              sx={{ fontSize: '6px', mr: 1 }}
                            />
                            <Typography
                              color="white"
                              fontWeight={600}
                              textTransform="uppercase"
                              variant="caption"
                            >
                              {
                                selectedFlatRows
                                  .map((row) => row.original)
                                  .filter(isRowSelectable).length
                              }{' '}
                              {selectedResumeLabel}
                            </Typography>
                          </Box>
                          {selectedResumeAction && (
                            <Box ml={4}>{selectedResumeAction}</Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <DraggableWrapper
                        isDraggable={isDraggable}
                        key={row.id}
                        row={row}
                      >
                        {(provided, snapshot) => {
                          const { className = '', ...rowProps } =
                            getRowProps(row);
                          return (
                            // TODO: revisit
                            // @ts-ignore
                            <TableRow
                              className={clsx(className)}
                              tabIndex={0}
                              {...row.getRowProps(rowProps)}
                              ref={provided?.innerRef}
                              {...provided?.draggableProps}
                              variant={
                                snapshot?.isDragging ? 'drag' : undefined
                              }
                            >
                              {row.cells.map((cell) => {
                                return (
                                  // eslint-disable-next-line react/jsx-key
                                  <TableCell
                                    {...cell.getCellProps([
                                      {
                                        // @ts-ignore
                                        className: cell.column.className,
                                        // @ts-ignore
                                        style: cell.column.style,
                                      },
                                    ])}
                                    // @ts-ignore
                                    align={cell.column.align}
                                    // @ts-ignore
                                    padding={cell.column.padding}
                                  >
                                    {cell.render('Cell', {
                                      dragHandleProps:
                                        provided?.dragHandleProps,
                                      isDragging: snapshot?.isDragging || false,
                                    })}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        }}
                      </DraggableWrapper>
                    );
                  })}
                  {((!showFooter && rows.length === 0) || allColumnsHidden) && (
                    <TableRow>
                      <TableCell
                        // @ts-ignore
                        colSpan="100%"
                        size="medium"
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography>
                          {instance.state.globalFilter?.length
                            ? 'No matching results. Please revise your search.'
                            : 'No data to display.'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {provided?.placeholder}
                </TableBody>
                {showFooter && (
                  <TableFooter>
                    {footerGroups.map((footerGroup: HeaderGroup) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <TableRow {...footerGroup.getFooterGroupProps()}>
                          {footerGroup.headers.map((column: any) => {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <TableCell
                                align={column.align}
                                padding={column.padding}
                                {...column.getFooterProps()}
                                sx={{
                                  backgroundColor: isLight ? gray9 : gray1,
                                }}
                              >
                                {column.render('Footer')}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableFooter>
                )}
              </Table>
            )}
          </DragDropWrapper>
        </TableContainer>
        {pagination && (
          <MuiTablePagination
            component="div"
            rowsPerPage={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            sx={{ left: 0, position: 'sticky' }}
            {...paginationProps}
          />
        )}
      </Paper>
    );
  },
);

const DragDropWrapper = ({
  children,
  onDragEnd,
}: {
  children: (provided: DroppableProvided | null) => React.ReactElement;
  onDragEnd: any;
}) => {
  const handleRowDnD = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (onDragEnd) onDragEnd(result.source.index, result.destination.index);
  };

  if (!onDragEnd) return <>{children(null)}</>;
  return (
    <DragDropContext onDragEnd={handleRowDnD}>
      <Droppable droppableId="droppable">
        {(provided) => children(provided)}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableWrapper = ({
  children,
  isDraggable,
  row,
}: {
  children: (
    provided: DraggableProvided | null,
    snapshot: DraggableStateSnapshot | null,
  ) => React.ReactElement;
  isDraggable: boolean;
  row: Row;
}) => {
  if (!isDraggable) return <>{children(null, null)}</>;
  return (
    <Draggable draggableId={row.id} index={row.index}>
      {(provided, snapshot) => children(provided, snapshot)}
    </Draggable>
  );
};

DataTable.displayName = 'DataTable';

export default DataTable;
