export const formValidator = {
  allowOnlyNumbers: {
    message: '',
    value: /^[0-9]+$/,
  },
  cardExpiry: {
    message: '',
    value: /^(0[1-9]|1[0-2])\/\d{2}$/,
  },
  email: {
    message: '',
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
};
