/* istanbul ignore file */
export enum TablePageSize {
  Large = 100,
  Medium = 50,
  Small = 10,
}

export enum TablePagination {
  Automatic = 'automatic',
  Manual = 'manual',
}

export const DEFAULT_TABLE_ROWS_PER_PAGE = [
  TablePageSize.Small,
  TablePageSize.Medium,
  TablePageSize.Large,
];
export const DEFAULT_TABLE_PAGE_SIZE = DEFAULT_TABLE_ROWS_PER_PAGE[0];

export * from './Columns';

export { default as AutoCompleteFilter } from './AutoCompleteFilter';
export { default as CourseTable } from './CourseTable';
export { default as CourseTableFilters } from './CourseTableFilters';
export {
  default as DataTable,
  IndeterminateCheckbox,
  SET_SELECTED_ROWS,
} from './DataTable';

export type { BaseCourse } from './CourseTable';
export type {
  Cell,
  DataTableProps,
  TableColumn,
  TableInstance,
} from './DataTable';
