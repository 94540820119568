import { Settings } from 'luxon';

import { createRequiredContext } from 'helpers/createRequiredContext';
import { getTimezoneAbbreviation, guessSystemTimezone } from 'helpers/datetime';

import { useProfile } from 'hooks/api';

import type { ReactNode } from 'react';

export interface TimezoneContextType {
  currentTimeZone: string;
  currentTimeZoneAbbreviation: string;
  defaultToSchoolTimezone: boolean;
}

export const [TimezoneContext, useTimezoneContext] =
  createRequiredContext<TimezoneContextType>();

interface Props {
  children: ReactNode;
}

export const TimezoneProvider = ({ children }: Props) => {
  const { userTimezone = null, defaultToSchoolTimezone } = useProfile();

  const currentTimeZone = userTimezone ? userTimezone : guessSystemTimezone();

  // set dayjs timezone to the user's timezone. This is on the component body cause we need for it to run before child's first render
  setLuxonDefaultDate(currentTimeZone);

  setUpWindowsTimezone({
    currentTimeZone,
  });
  return (
    <TimezoneContext.Provider
      value={{
        currentTimeZone,
        currentTimeZoneAbbreviation: getTimezoneAbbreviation(currentTimeZone),
        defaultToSchoolTimezone: !!defaultToSchoolTimezone,
      }}
    >
      {children}
    </TimezoneContext.Provider>
  );
};

function setLuxonDefaultDate(timezone: string | null) {
  if (timezone) {
    Settings.defaultZone = timezone;
  } else {
    Settings.defaultZone = 'system';
  }
}

function setUpWindowsTimezone({
  currentTimeZone,
}: {
  currentTimeZone: string;
}) {
  window.timezone = currentTimeZone;
}

export default TimezoneProvider;
