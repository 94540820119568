import { useTheme } from '@mui/material';

import { ThemeMode } from 'tools/constants/profile';

const useIsLightMode = () => {
  const {
    palette: { mode },
  } = useTheme();

  return mode === ThemeMode.Light;
};

export default useIsLightMode;
