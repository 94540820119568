import { createContext, useEffect, useState } from 'react';

export interface Breadcrumb {
  name: string;
  url: string;
}

export const BreadcrumbsContext = createContext<{
  breadcrumbs: Array<Breadcrumb>;
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Array<Breadcrumb>>>;
}>({ breadcrumbs: [], setBreadcrumbs: () => {} });

interface Props {
  children: React.ReactNode;
  initialBreadcrumbs?: Array<Breadcrumb>;
}

/**
 * @deprecated the breadcrumb will be deprecated on the new platform navigation
 */
export const BreadcrumbsProvider = ({
  children,
  initialBreadcrumbs = [],
}: Props) => {
  const [breadcrumbs, setBreadcrumbs] =
    useState<Array<Breadcrumb>>(initialBreadcrumbs);

  useEffect(() => {
    const title = breadcrumbs
      .map(({ name }) => name)
      .reverse()
      .join(' - ');
    document.title = title;
  }, [breadcrumbs]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
