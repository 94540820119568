import { SnackbarProvider } from 'notistack';

import AddPaymentPageWebView from 'components/WebView/AddPaymentMethod';

const AddPaymentMethod = () => {
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={3000}
      maxSnack={1}
    >
      <AddPaymentPageWebView />
    </SnackbarProvider>
  );
};

export default AddPaymentMethod;
