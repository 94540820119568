import { Autocomplete, BaseTextFieldProps, TextField } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';

import { getSchoolList } from 'actions/profileActions';

import { useDebounce } from 'hooks';

import type { SchoolSearch } from 'tools/types/school';

interface Props extends BaseTextFieldProps {
  disabled?: boolean;
  onChange: Function;
  value?: SchoolSearch | null;
}

const SchoolAutoComplete = forwardRef(
  (
    { disabled = false, onChange, value: initialValue = null, ...rest }: Props,
    ref,
  ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState<Array<SchoolSearch>>([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(initialValue);
    const debouncedSearchTerm = useDebounce(searchTerm, 350);

    useEffect(() => {
      (async () => {
        setLoading(true);
        if (debouncedSearchTerm) {
          const schools = await getSchoolList({
            searchQuery: debouncedSearchTerm,
          });
          setOptions(schools);
        }
        setLoading(false);
      })();
    }, [debouncedSearchTerm]);

    return (
      <Autocomplete
        disableClearable
        disabled={disabled}
        getOptionLabel={(school: SchoolSearch) => school.name}
        id="registration-school-names"
        loading={loading}
        onChange={(_, selectedSchool: SchoolSearch) => {
          onChange(selectedSchool);
          setValue(selectedSchool);
        }}
        options={options}
        ref={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputLabelProps={{ required: false }}
            label="School Name"
            onChange={(event) => {
              if (!loading) {
                setLoading(true);
              }
              setSearchTerm(event.target.value);
            }}
            value={searchTerm}
            {...rest}
          />
        )}
        // @ts-ignore
        value={value}
      />
    );
  },
);

export default SchoolAutoComplete;
