import axios from 'actions';

import type { ThemeMode } from 'tools/constants/profile';

import type { SchoolSearch } from 'tools/types/school';

// response missing allowIssuedIDEdit, isAllowedToCreateCourse, schoolName
export const addSchoolAccount = (body: {
  issuedID: string;
  schoolID: string;
}) =>
  axios
    .post('/api/v2/profile/account', body)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.error));

export const getMyAvatar = () =>
  axios
    .get('/api/v2/profile/avatar', {
      responseType: 'blob',
    })
    .then(({ data }) => {
      return window.URL.createObjectURL(data);
    })
    .catch(() => '');

export const getSchoolList = ({ searchQuery }: { searchQuery: string }) =>
  axios
    .get<Array<SchoolSearch>>(`/api/v2/profile/schools?name=${searchQuery}`)
    .then(({ data }) => data)
    .catch(() => []);

export const updateIssuedID = (
  { accountID }: { accountID: number },
  body: { issuedID: string },
) =>
  axios
    .put(`/api/v2/profile/account/${accountID}`, body)
    .catch(({ response }) => Promise.reject(response.data.error));

export const updatePassword = (body: {
  currentPassword: string;
  newPassword: string;
}) =>
  axios
    .put('/api/v2/profile/password', body)
    .catch(({ response }) => Promise.reject(response.data.error));

export const updateTheme = (body: { theme: ThemeMode }) =>
  axios
    .put('/api/v2/profile/theme', body)
    .catch(({ response }) => Promise.reject(response.data.error));

export const updateTimezone = (body: {
  defaultToSchoolTimezone?: boolean;
  timezone: string | null;
}) =>
  axios
    .put('/api/v2/profile/timezone', body)
    .catch(({ response }) => Promise.reject(response.data.error));

export const uploadAvatar = (body: FormData) =>
  axios
    .post('/api/v2/profile/avatar', body)
    .catch(({ response }) => Promise.reject(response.data.error));
