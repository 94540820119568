/**
 * Function to format the card expiry date with a "/" in MM/YY format.
 * @param e React keyboard event
 * @returns string
 */
export const formatCardExpiry = (
  e: React.KeyboardEvent<HTMLInputElement> | any,
): void => {
  if ([8].indexOf(e.keyCode) !== -1) return; // Allowing the user to press backspace (to delete a character)

  e.target.value = e.target.value
    .replace(
      /^([1-9]\/|[2-9])$/g,
      '0$1/', // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1/', // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      '0$1/$2', // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1/$2', // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      '0', // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d/]|^[/]*$/g,
      '', // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      '/', // Prevent entering more than 1 `/`
    );
};

/**
 * Function to format the card expiry date with a "/" in MM/YY format.
 * @param e React keyboard event
 * @returns string
 */
export const formatMobileNumber = (
  e: React.KeyboardEvent<HTMLInputElement> | any,
): void => {
  if ([8].indexOf(e.keyCode) !== -1) return; // Allowing the user to press backspace (to delete a character)

  e.target.value = e.target.value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};
