import { ThemeMode } from 'tools/constants/profile';

import {
  blue3,
  blue4,
  blue5,
  blue6,
  green3,
  green4,
  green5,
  green6,
  primaryBlue,
  primaryGreen,
  primaryRed,
  primaryYellow,
  red3,
  red4,
  red5,
  red6,
  yellow3,
  yellow4,
  yellow5,
  yellow6,
} from './colors';
import {
  getDefaultBackgroundColor,
  getHeadingColor,
  getPaperBackgroundColor,
} from './muiTheme';

import type { ITheme } from '@gooddata/sdk-model';

export const CHART_COLORS = [
  {
    fill: green4,
    primary: primaryGreen,
    stroke: green3,
  },
  {
    fill: blue4,
    primary: primaryBlue,
    stroke: blue3,
  },
  {
    fill: yellow4,
    primary: primaryYellow,
    stroke: yellow3,
  },
  {
    fill: red4,
    primary: primaryRed,
    stroke: red3,
  },
  {
    fill: green5,
    primary: primaryGreen,
    stroke: green4,
  },
  {
    fill: blue5,
    primary: primaryBlue,
    stroke: blue4,
  },
  {
    fill: yellow5,
    primary: primaryYellow,
    stroke: yellow4,
  },
  {
    fill: red5,
    primary: primaryRed,
    stroke: red4,
  },
  {
    fill: green6,
    primary: primaryGreen,
    stroke: green5,
  },
  {
    fill: blue6,
    primary: primaryBlue,
    stroke: blue5,
  },
  {
    fill: yellow6,
    primary: primaryYellow,
    stroke: yellow5,
  },
  {
    fill: red6,
    primary: primaryRed,
    stroke: red5,
  },
];

export const getTheme = (mode: ThemeMode.Dark | ThemeMode.Light): ITheme => {
  const isLight = mode === ThemeMode.Light;

  const borderColor = 'transparent';
  const defaultBackgroundColor = getDefaultBackgroundColor(isLight);
  const paperBackgroundColor = getPaperBackgroundColor(isLight);
  const headingColor = getHeadingColor(isLight);

  return {
    dashboards: {
      content: {
        backgroundColor: defaultBackgroundColor,
        widget: {
          dropShadow: true,
          title: {
            color: headingColor,
            textAlign: 'left',
          },
        },
      },
      filterBar: {
        backgroundColor: defaultBackgroundColor,
        borderColor,
      },
      title: {
        backgroundColor: defaultBackgroundColor,
        borderColor,
      },
    },
    palette: {
      complementary: {
        c0: paperBackgroundColor,
        c9: headingColor,
      },
      primary: {
        base: primaryGreen,
      },
    },
  };
};
