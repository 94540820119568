import type { Seconds } from 'tools/types';

export const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

/** Restrict value between a lower and upper bound */
export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

/** Forces a function to wait a certain amount of time before running again */
export const debounce = <T extends (...args: any) => any>(fn: T, wait = 0) => {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), wait);
  };
};

export const formatBool = (isTrue: boolean) => (isTrue ? 'Yes' : 'No');

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatHideGrades = (hideGrades: string) => {
  if (hideGrades === '0') return 'None';
  if (hideGrades === '-1') return 'Yes';
  return `${hideGrades} ${pluralize('day', +hideGrades)}`;
};

export const formatNull = <T>(
  value: T | null,
  format: (value: T) => string = String,
) => (value === null ? '- -' : format(value));

export const formatNullish = <T>(
  value: T | null | undefined,
  format: (value: T) => string = String,
) => (value == null ? '- -' : format(value));

// time limit (in minutes)
export const formatTimeLimit = (timeLimit: Seconds) => {
  const hours = Math.floor(timeLimit / 3600);
  const minutes = Math.floor((timeLimit / 60) % 60);

  if (hours || minutes) {
    return `${hours.toLocaleString()} hr ${String(minutes).padStart(
      2,
      '0',
    )} min`;
  }

  return '- -';
};

// TODO Update types
// Ref: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/lodash/common/object.d.ts#L1032
export const get = (from: any, selector: string) =>
  selector
    .replace(/\[([^[\]]*)\]/g, '.$1.')
    .split('.')
    .filter((t) => t !== '')
    .reduce((prev, cur) => prev && prev[cur], from);

export const getPercentage = (
  totalAwarded: number,
  totalPossible: number,
  options: { decimals?: number; roundDown?: boolean } = {
    decimals: 0,
    roundDown: false,
  },
) => {
  const overallRatio = totalPossible ? totalAwarded / totalPossible : 0;
  return `${(options.roundDown ? roundDown : round)(
    overallRatio * 1e2,
    options.decimals,
  )}%`;
};

// calculate proportional stroke width for circular progress
// - the viewbox for circular progress is 22 by 22 so a thickness of 22
// - would mean a full circle, so we would just need to solve a ratio equation
// - x / viewbox = width / (size / 2)
export const getStrokeWidth = (width: number, size: number) => {
  const VIEWBOX_WIDTH = 22;
  return (VIEWBOX_WIDTH * width) / (size / 2);
};

export const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = src;
    script.onload = resolve;
    script.onerror = (msg, url, lineNo, columnNo, err) => reject(err);
    document.getElementsByTagName('head')[0].appendChild(script);
  });

/** Filters out properties from an object */
export const omit = (obj: { [key: string]: any }, arr: string[]) =>
  Object.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce(
      (acc, key) => ((acc[key] = obj[key]), acc),
      {} as { [key: string]: any },
    );

export const pluralize = (word: string, number = 1) =>
  number === 1 ? word : `${word}s`;

export const round = (number: number, decimals = 0) => {
  const factor = Math.pow(10, decimals);
  return Math.round((number + Number.EPSILON) * factor) / factor;
};

export const roundDown = (number: number, decimals = 0) => {
  const factor = Math.pow(10, decimals);
  return Math.floor((number + Number.EPSILON) * factor) / factor;
};

export const snakeCase = (str: string) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join('_');

export const stripHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

/**  Ensures that a function is called at most once in a specified time period */
export const throttle = <T extends (...args: any) => any>(fn: T, wait = 0) => {
  let inThrottle: boolean,
    lastFn: ReturnType<typeof setTimeout>,
    lastTime: number;
  return function (this: any, ...args: any[]) {
    if (!inThrottle) {
      fn.apply(this, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(this, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

/** Returns array of unique elements that matches some criteria */
export const uniqBy = (arr: any[], key: string) => {
  const seen = new Set();
  return arr.filter((item) => {
    const k = item[key];
    if (seen.has(k)) {
      return false;
    }
    seen.add(k);
    return true;
  });
};
