import { BASE_URL } from '..';

export const RETENTION_BASE_URL = (apiVersion: 'v3' | 'v4' = 'v3') =>
  `${BASE_URL}/${apiVersion}/retention`;
export const getRetentionBaseUrl = (apiVersion?: 'v3' | 'v4') =>
  `${RETENTION_BASE_URL(apiVersion)}/configuration`;
export const RETENTION_DAILY_ALERTS_URL = `${RETENTION_BASE_URL()}/dailyAlerts`;
export const RETENTION_DATA_SOURCES_URL = `${RETENTION_BASE_URL()}/dataSources`;
export const RETENTION_OVERVIEW_URL = `${RETENTION_BASE_URL()}/overview`;
export const RETENTION_OVERVIEW_USERS_URL = `${RETENTION_OVERVIEW_URL}/users`;
export const RETENTION_SCHOOLS_URL = `${RETENTION_BASE_URL()}/schools`;
export const RETENTION_USER_URL = `${RETENTION_BASE_URL()}/user`;

interface RetentionProps {
  /**
   * The api version you want to call
   * - using v4 for `updateConfigurationsRiskLevels` and `updateConfigurationsWeightsThresholds`
   * @default v3
   * */
  apiVersion?: 'v3' | 'v4';
  schoolID: string | number;
}

export const getConfigurationUrl = ({ schoolID, apiVersion }: RetentionProps) =>
  `${getRetentionBaseUrl(apiVersion)}/${schoolID}`;

export const getConfigurationRiskFactorUrl = (props: RetentionProps) =>
  `${getConfigurationUrl(props)}/riskFactor`;

export const getConfigurationRiskLevelUrl = (props: RetentionProps) =>
  `${getConfigurationUrl(props)}/riskLevel`;

export interface RetentionWeightsThresholdsProps extends RetentionProps {
  programID: string | number;
}

export const getConfigurationWeightsThresholdsUrl = (props: RetentionProps) =>
  `${getConfigurationUrl(props)}/thresholds`;

export const getDailyAlertsUrl = ({ schoolID }: RetentionProps) =>
  `${RETENTION_DAILY_ALERTS_URL}/${schoolID}`;

export const getDataSourcesUrl = ({ schoolID }: RetentionProps) =>
  `${RETENTION_DATA_SOURCES_URL}/${schoolID}`;

interface RetentionUserProps {
  userID: string | number;
}

export const getUserUrl = ({ userID }: RetentionUserProps) =>
  `${RETENTION_USER_URL}/${userID}`;
