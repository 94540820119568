// Note: Cardknox module does not support typescript, so we have to use ts-nocheck to ignore typescript errors.
// @ts-nocheck
import { forwardRef } from 'react';

import { CARDKNOX_LIB_VERSION } from 'helpers/env';

interface ICardknoxIFieldProps {
  inputStyles: Object;
  onLoad: () => void;
  onUpdate: (data) => void;
  placeholder: string;
  type: string;
}

const CardknoxIField = forwardRef((props: ICardknoxIFieldProps, ref) => {
  const { onLoad, inputStyles, placeholder, type, onUpdate } = props;
  const fieldmapping = {
    ach: { iframeId: 'ach', inputId: 'ach-token', inputName: 'xACH' },
    card: {
      iframeId: 'card-number',
      inputId: 'card-number-token',
      inputName: 'xCardNum',
    },
    cvv: { iframeId: 'cvv', inputId: 'cvv-token', inputName: 'xCVV' },
  };

  addIfieldKeyPressCallback(function (data) {
    onUpdate(data);
  });

  enableAutoFormatting(' ');

  return (
    <>
      <iframe
        data-ifields-id={fieldmapping[type].iframeId}
        data-ifields-placeholder={placeholder}
        onLoad={() => {
          setIfieldStyle(fieldmapping[type].iframeId, inputStyles);
          onLoad();
        }}
        src={`https://cdn.cardknox.com/ifields/${CARDKNOX_LIB_VERSION}/ifield.htm`}
        title={placeholder}
      ></iframe>
      <input
        aria-label="Hidden input"
        data-ifields-id={fieldmapping[type].inputId}
        name={fieldmapping[type].inputName}
        ref={ref}
        type="hidden"
      />
    </>
  );
});

export default CardknoxIField;
