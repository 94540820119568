import * as Sentry from '@sentry/browser';

import axios from 'actions';

import { PROFILE_ME_URL } from 'tools/urls';

import type { Profile } from 'tools/types/profile';

export const clearSession = () => {
  document.cookie =
    'ki=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.thecoursekey.com';

  window.gtag('set', {
    dimension1: '',
    dimension2: '',
    dimension3: '',
    dimension4: '',
  });

  sessionStorage.clear();

  // clear user context
  Sentry.configureScope((scope) => scope.clear());
};

export const getPasswordReset = ({ email }: { email: string }) =>
  axios
    .get(`/api/v2/auth/forgotpassword/${email}`)
    .catch(({ response }) => Promise.reject(response.data.error));

export const getProfile = () =>
  axios
    .get<Profile>(PROFILE_ME_URL)
    .then(({ data: profileInfo }) => {
      setLoggingData(profileInfo);
      window.gtag('set', {
        dimension2: profileInfo.userID.toString(),
      });
      return profileInfo;
    })
    .catch(({ response }) => Promise.reject(response.data.error));

export const getUserEmail = ({
  answer,
  userID,
}: {
  answer: string;
  userID: number;
}) =>
  axios
    .get<{ email: string }>(
      `/api/v2/auth/forgotemail?userID=${userID}&answer=${answer}`,
    )
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.error));

export interface SecurityInfo {
  question: {
    enable: boolean;
    id: number;
    question: string;
  };
  userID: string;
}

export const getUserSecurityQuestion = ({
  firstName,
  issuedID,
  lastName,
}: {
  firstName: string;
  issuedID: string;
  lastName: string;
}) =>
  axios
    .get<SecurityInfo>(
      `/api/v2/auth/question/user?firstName=${firstName}&lastName=${lastName}&issuedID=${issuedID}`,
    )
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.error));

export const login = (body: { email: string; password: string }) =>
  axios
    .post<Profile>('/api/v2/auth/login', body)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.error));

export const loginSSO = (body: { email: string }) =>
  axios
    .get<{
      route: string;
    }>('/api/v2/auth/login/sso', {
      params: {
        email: body.email,
      },
    })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.error));

export const logout = () =>
  axios
    .delete('/api/v2/auth/logout')
    .then(() => {
      window.location.assign('/login');
      clearSession();
    })
    .catch(({ response }) => Promise.reject(response.data.error));

export const resetPassword = (
  { userID }: { userID: number },
  body: { newPass: string; oldPass: string },
) =>
  axios
    .post<{ error?: Error }>(`/api/v2/auth/resetpassword/${userID}`, body)
    .then(({ data }) => {
      if (data?.error) {
        // TODO: Change BE to throw error
        return Promise.reject({
          response: { data },
        });
      }
      return null;
    })
    .catch(({ response }) => Promise.reject(response.data.error));

const setLoggingData = (profile: Profile) => {
  const { email, userID, isTeacher } = profile;

  // Google Analytics
  window.gtag('set', {
    dimension2: userID.toString(),
  });

  // Sentry error capturing
  Sentry.setUser({ email, id: String(userID), teacher: isTeacher });
};

export interface SecurityQuestion {
  answer: string;
  id: number;
}

export interface TermsAndSecurityInfo {
  hasAgreedToTerms: boolean;
  newPassword: string;
  securityQuestion: string;
}

export const updateTermsAndSecurityDetails = (
  securityInfo: TermsAndSecurityInfo,
) =>
  axios
    .post('/api/v2/auth/policy', securityInfo)
    .catch(({ response }) => Promise.reject(response.data.error));
