import { WarningRounded as WarningIcon } from '@mui/icons-material';
import { Box, Button, Typography, TypographyProps } from '@mui/material';

interface Props {
  message?: string | null;
  onRetry?: (event: React.MouseEvent) => void;
  showRetry?: boolean;
  title: string;
  titleProps?: TypographyProps & {
    component?: React.ReactNode;
  };
}

const ErrorView = ({
  message = '',
  onRetry,
  showRetry = true,
  title,
  titleProps,
}: Props) => (
  <Box
    display="grid"
    padding={4}
    style={{ placeContent: 'center', placeItems: 'center' }}
  >
    <WarningIcon color="error" sx={{ fontSize: '64px', marginBottom: 2 }} />
    <Typography component="p" gutterBottom variant="h5" {...titleProps}>
      {title}
    </Typography>
    <Typography color="textSecondary" variant="body2">
      {message}
    </Typography>

    {/* Retry */}
    {showRetry && (
      <Button
        fullWidth
        onClick={onRetry}
        size="medium"
        sx={{ marginTop: '16px', maxWidth: '128px' }}
        variant="outlined"
      >
        Retry
      </Button>
    )}
  </Box>
);

export default ErrorView;
