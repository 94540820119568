import { Add as AddIcon } from '@mui/icons-material';
import { Remove as SubtractIcon } from '@mui/icons-material';
import { Box, IconButton, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';

import { useIsLightMode } from 'hooks';

import { actionButtonStyles, StyledTextInput } from './styled';

interface Props extends Omit<TextFieldProps, 'onChange' | 'value'> {
  onChange: (value: string | number) => void;
  value: number | string;
}

const AddSubtractField = forwardRef(
  ({ onChange, value, ...props }: Props, ref?: React.Ref<HTMLInputElement>) => {
    const { inputProps: { min, max, step } = {} } = props;
    const minValue =
      min === undefined || Number.isNaN(min) ? null : Number(min);
    const maxValue =
      max === undefined || Number.isNaN(max) ? null : Number(max);
    const isLight = useIsLightMode();
    const isSubtractDisabled =
      props.disabled ||
      (minValue !== null && !Number.isNaN(value) && Number(value) <= minValue);
    const isAddDisabled =
      props.disabled ||
      (maxValue !== null && !Number.isNaN(value) && Number(value) >= maxValue);
    const buttonIntervalStep = Number(step) || 1;

    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
              {
                '-webkit-appearance': 'none',
              },
          }}
        >
          <IconButton
            {...actionButtonStyles({ isDisabled: isSubtractDisabled, isLight })}
            disabled={isSubtractDisabled}
            onClick={() => {
              const nextValue = (Number(value) || 0) - buttonIntervalStep;
              const newValue =
                minValue !== null ? Math.max(nextValue, minValue) : nextValue;
              onChange(Number(newValue.toFixed(2)));
            }}
          >
            <SubtractIcon fontSize="small" />
          </IconButton>
          <StyledTextInput
            {...props}
            inputProps={{
              ...props.inputProps,
              value,
            }}
            onChange={(e) => onChange(e.target.value)}
            ref={ref}
            sx={(theme) => ({ border: `1px solid ${theme.palette.divider}` })}
            type="number"
            value={value}
          />
          <IconButton
            {...actionButtonStyles({
              isAddButton: true,
              isDisabled: isAddDisabled,
              isLight,
            })}
            disabled={isAddDisabled}
            onClick={() => {
              const nextValue = (Number(value) || 0) + buttonIntervalStep;
              const newValue =
                maxValue !== null ? Math.min(nextValue, maxValue) : nextValue;
              onChange(Number(newValue.toFixed(2)));
            }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  },
);

export default AddSubtractField;
