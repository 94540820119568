import { BASE_URL } from '.';

const API_VERSION = 'v3';

export const USERS_BASE_URL = `${BASE_URL}/${API_VERSION}/users`;
export const USER_SCHOOLS_URL = `${USERS_BASE_URL}/schools`;

interface UserProps {
  userID: string | number;
}

export const getUserUrl = ({ userID }: UserProps) =>
  `${USERS_BASE_URL}/${userID}`;

export const getUserCourseUrl = (props: UserProps) =>
  `${getUserUrl(props)}/courses`;

export const getUserCourseExportUrl = ({
  type,
  ...props
}: UserProps & { type: 'fullRecord' | 'summary' }) =>
  `${getUserCourseUrl(props)}/export/${type}`;

export const getUserOverviewUrl = (userID: number | string) =>
  `${getUserUrl({ userID })}/overview`;
