export enum QuestionType {
  Essay = 'plaintext',
  FillBlank = 'clozetext',
  Highlight = 'highlight',
  Match = 'association',
  Math = 'clozeformula',
  Matrix = 'choicematrix',
  MultipleChoice = 'mcq',
  Pen = 'drawing',
  ShortAnswer = 'shorttext',
  TrueFalse = 'mcq',
}

export enum CKQuestionType {
  Essay = 'ckEssay',
  FillBlank = 'ckFill',
  Highlight = 'ckHighlight',
  Match = 'ckMatch',
  Math = 'ckMath',
  Matrix = 'ckMatrix',
  MultipleChoice = 'ckMCQ',
  Pen = 'ckDrawing',
  ShortAnswer = 'ckShort',
  TrueFalse = 'ckTrueFalse',
}
