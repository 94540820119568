import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { forwardRef } from 'react';
import {
  LinkProps as RouteLinkProps,
  Link as RouterLink,
} from 'react-router-dom';

const Link = forwardRef<
  HTMLAnchorElement,
  MuiLinkProps & Omit<RouteLinkProps, 'color' | 'ref'>
>((props, ref) => <MuiLink {...props} component={RouterLink} ref={ref} />);

export default Link;
