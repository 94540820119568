import { BASE_URL } from '.';

const API_VERSION = 'v3';

export const USER_GROUPS_BASE_URL = `${BASE_URL}/${API_VERSION}/users/groups`;

export const USER_GROUPS_MEMBER_BULK_ADD_URL = `${USER_GROUPS_BASE_URL}/members/bulkAdd`;
export const USER_GROUPS_MEMBER_BULK_DELETE_URL = `${USER_GROUPS_BASE_URL}/members/bulkDelete`;

export const USER_GROUPS_CONTACT_BULK_ADD_URL = `${USER_GROUPS_BASE_URL}/contacts/bulkAdd`;
export const USER_GROUPS_CONTACT_BULK_DELETE_URL = `${USER_GROUPS_BASE_URL}/contacts/bulkDelete`;

export const USER_GROUPS_BY_STAFF_URL = `${USER_GROUPS_BASE_URL}/me`;

export const getUserGroupURL = (userGroupID: number | string) =>
  `${USER_GROUPS_BASE_URL}/group/${userGroupID}`;

export const getUpdateUserGroupURL = (userGroupID: number | string) =>
  `${USER_GROUPS_BASE_URL}/group/${userGroupID}`;
