import {
  Checkbox,
  type CheckboxProps,
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  type SelectProps as MUISelectProps,
  TextField,
  type TextFieldProps,
} from '@mui/material';
import { forwardRef, UIEvent, useMemo, useRef, useState } from 'react';

type Value = any | Array<any>;
type Option = Value | { disabled?: boolean; label: string; value: Value };
type RenderOption = {
  label: string;
  value: string;
};

const checkboxProps: CheckboxProps = {
  sx: {
    padding: 0,
  },
};

interface SelectOptionProps {
  checked: boolean;
  label: string;
  multiple?: boolean;
  value: string;
}

const SelectOption = forwardRef<HTMLLIElement | null, SelectOptionProps>(
  ({ checked, label, multiple, value, ...props }, ref) => {
    return (
      <MenuItem
        {...props}
        ref={ref}
        selected={checked}
        sx={{
          width: '300px',
        }}
        value={value}
      >
        {multiple && (
          <ListItemIcon>
            <Checkbox checked={checked} {...checkboxProps} />
          </ListItemIcon>
        )}
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={label}
        >
          {label}
        </span>
      </MenuItem>
    );
  },
);

type Props = {
  /** @deprecated Use carefully and only if really necessary. Its implementation is not scalable.  */
  emptyLabel?: string;
  enableVirtualization?: boolean;
  LabelSpanStyle?: React.CSSProperties;
  MenuItemProps?: MenuItemProps;
  onChange?: (value: Value) => void;
  options: Array<Option>;
  renderValue?: (value: Value) => string | undefined;
  value?: Value;
} & (
  | {
      multiple?: false;
      renderLabel?: string;
    }
  | { multiple?: true; renderLabel: string }
);

export type SelectProps = Props &
  Omit<MUISelectProps, 'margin' | 'onChange' | 'renderValue'> &
  Omit<TextFieldProps, 'onChange' | 'value'>;

const Select = forwardRef(
  (
    {
      displayEmpty = true,
      enableVirtualization = false,
      multiple = false,
      onChange = () => {},
      options = [],
      placeholder = '',
      renderLabel,
      renderValue,
      value,
      emptyLabel,
      MenuItemProps,
      LabelSpanStyle,
      ...rest
    }: SelectProps,
    ref?: React.Ref<any>,
  ) => {
    const renderOptions = options.map((option: Option) => {
      if (typeof option === 'object') return option;
      return { label: String(option), value: option };
    });

    const { isSelectAllChecked, isSelectAllIndeterminate } = (() => {
      let isSelectAllChecked = false;
      let isSelectAllIndeterminate = false;

      if (Array.isArray(value) && renderOptions.length > 0) {
        isSelectAllChecked = renderOptions.every((option) =>
          value.includes(option.value),
        );
        isSelectAllIndeterminate =
          !isSelectAllChecked &&
          renderOptions.some((option) => value.includes(option.value));
      }

      return {
        isSelectAllChecked,
        isSelectAllIndeterminate,
      };
    })();

    // -----------------------------------------------------------------------------------------------
    const handleRenderValue = (value: Value): React.ReactNode => {
      if (!Array.isArray(value)) {
        if (typeof value === 'string' || typeof value === 'number')
          return value;
        return null;
      }

      const { length } = value;

      if (isSelectAllChecked) return `All ${renderLabel}`;
      if (length === 0) return emptyLabel ?? `No ${renderLabel}`;
      if (length === 1)
        return renderOptions.find((option) => option.value === value[0])?.label;
      return `${length} ${renderLabel}`;
    };

    // -----------------------------------------------------------------------------------------------
    const handleSelectAllClick = (e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();

      const optionValues = renderOptions.map(({ value }) => value);
      const selectedValues = !isSelectAllChecked ? optionValues : [];

      onChange(selectedValues as unknown as Value);
    };

    const { fixedBeforeHeight, onScroll, reset, visibleOptions } =
      useSelectVirtualization({
        enableVirtualization,
        multiple,
        renderOptions,
      });

    function renderRenderOption({
      label: optionLabel,
      listId,
      value: optionValue,
    }: RenderOptionVirtualized) {
      return (
        <SelectOption
          checked={Array.isArray(value) && value.includes(optionValue)}
          key={`${listId}${optionLabel}`}
          label={optionLabel}
          multiple={multiple}
          value={optionValue}
        />
      );
    }

    // -----------------------------------------------------------------------------------------------
    return (
      <TextField
        defaultValue={multiple ? [] : ''}
        value={value}
        {...rest}
        onChange={(
          e:
            | React.ChangeEvent<HTMLInputElement> & {
                target: { value: Value };
              },
        ) => {
          const value = e.target.value;
          if (multiple) value.sort();
          onChange(value);
        }}
        ref={ref}
        select
        SelectProps={{
          ...(multiple
            ? {
                renderValue: (renderValue ||
                  handleRenderValue) as MUISelectProps['renderValue'],
              }
            : {}),
          displayEmpty,
          ...rest.SelectProps,
          ...(enableVirtualization && {
            onOpen: (e) => {
              reset();
              rest.SelectProps?.onOpen?.(e);
            },
          }),
          MenuProps: {
            ...rest.SelectProps?.MenuProps,
            PaperProps: {
              ...rest.SelectProps?.MenuProps?.PaperProps,
              ...(enableVirtualization && {
                onScroll: (e) => {
                  onScroll(e);
                  rest.SelectProps?.MenuProps?.PaperProps?.onScroll?.(e);
                },
              }),
            },
          },
          multiple,
        }}
      >
        {Boolean(placeholder) && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}

        {/* Select All */}
        {multiple && (
          <MenuItem
            onClickCapture={handleSelectAllClick}
            // any value is fine to indicate that all options are selected
            value={isSelectAllChecked ? value[0] : ''}
          >
            <ListItemIcon>
              <Checkbox
                checked={isSelectAllChecked}
                indeterminate={isSelectAllIndeterminate}
                {...checkboxProps}
              />
            </ListItemIcon>
            Select All
          </MenuItem>
        )}

        {/* THE BELOW ELEMENT IS USED TO COMPENSATE THE HIDE ELEMENTS HEIGHTS */}
        {enableVirtualization && (
          <MenuItem
            disabled
            sx={{
              display: fixedBeforeHeight ? 'block' : 'none',
              height: fixedBeforeHeight + 'px',
              opacity: 0,
              padding: 0,
            }}
          />
        )}
        {enableVirtualization
          ? visibleOptions.map(renderRenderOption)
          : renderOptions.map(
              ({ label: optionLabel, value: optionValue, disabled }) => (
                <MenuItem
                  {...MenuItemProps}
                  disabled={disabled}
                  key={optionValue}
                  value={optionValue}
                >
                  {multiple && (
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          Array.isArray(value) && value.includes(optionValue)
                        }
                        {...checkboxProps}
                      />
                    </ListItemIcon>
                  )}
                  <span style={LabelSpanStyle}>{optionLabel}</span>
                </MenuItem>
              ),
            )}
      </TextField>
    );
  },
);

export default Select;

/** This constant represents how many items each group will contain  */
export const GROUP_INTERVAL = 25;
const DESKTOP_BREAKPOINT = '600px';
/** This function is used to get the default item size */
const getElementHeight = () =>
  window.matchMedia(`(min-width: ${DESKTOP_BREAKPOINT})`).matches ? 36 : 48;
/** This constant represents the list gap */
const ELEMENT_GAP = 4;

interface SelectVirtualizationOptions {
  enableVirtualization?: boolean;
  multiple?: boolean;
  placeholder?: string;
  renderOptions: RenderOption[];
}

type RenderOptionVirtualized = RenderOption & { listId?: number };

interface SelectVirtualizationValue {
  activeVirtualizedIndex: number;
  fixedBeforeHeight: number;
  onScroll: (e: UIEvent<HTMLDivElement>) => void;
  reset: () => void;
  virtualizedGroups: RenderOptionVirtualized[][];
  visibleOptions: RenderOptionVirtualized[];
}

/**
 * This is a custom hook that helps with the virtualization of the current select
 */
function useSelectVirtualization({
  enableVirtualization,
  multiple,
  placeholder,
  renderOptions,
}: SelectVirtualizationOptions): SelectVirtualizationValue {
  const [activeVirtualizedIndex, setActiveVirtualizedIndex] = useState(0);

  const maxItemIndex = Math.ceil(renderOptions.length / GROUP_INTERVAL) - 1;
  const options = useMemo<RenderOptionVirtualized[]>(() => {
    return !enableVirtualization
      ? renderOptions
      : renderOptions.map((item, index) => {
          return {
            ...item,
            listId: index,
          };
        });
  }, [enableVirtualization, renderOptions]);

  const { virtualizedGroups, fixedBeforeHeight } = useMemo<{
    fixedBeforeHeight: number;
    virtualizedGroups: Option[][];
  }>(() => {
    if (!enableVirtualization) {
      return {
        fixedAfterHeight: 0,
        fixedBeforeHeight: 0,
        virtualizedGroups: [],
      };
    }

    const virtualizedGroups: Option[][] = [];
    for (let index = 0; index <= maxItemIndex; index++) {
      const startIndex = index * GROUP_INTERVAL;
      const endIndex = startIndex + GROUP_INTERVAL;
      virtualizedGroups.push(options.slice(startIndex, endIndex));
    }
    const hideElements =
      activeVirtualizedIndex > 1
        ? (activeVirtualizedIndex - 1) * GROUP_INTERVAL
        : 0;
    return {
      fixedBeforeHeight:
        hideElements === 0
          ? 0
          : hideElements * getElementHeight() +
            (hideElements - 1) * ELEMENT_GAP,
      virtualizedGroups,
    };
  }, [enableVirtualization, activeVirtualizedIndex, maxItemIndex, options]);
  const lastScroll = useRef(0);
  const groupStartPixel = useMemo(() => {
    let fixCount = 0;
    if (multiple) fixCount++;
    if (placeholder) fixCount++;
    const { list } = virtualizedGroups.reduce<{
      acc: number;
      list: number[];
    }>(
      (prev, current) => {
        prev.list.push(prev.acc);
        prev.acc += current.length * (getElementHeight() + ELEMENT_GAP);
        return prev;
      },
      {
        acc: fixCount * getElementHeight(),
        list: [],
      },
    );
    return list;
  }, [virtualizedGroups, multiple, placeholder]);

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop: number = target.scrollTop;
    if (scrollTop < lastScroll.current) {
      if (activeVirtualizedIndex > 1) {
        // rules for change active list
        const nextActive = groupStartPixel.findIndex((item, index) => {
          return (
            index + 1 === groupStartPixel.length ||
            groupStartPixel[index + 1] > scrollTop
          );
        });
        if (nextActive < activeVirtualizedIndex) {
          setActiveVirtualizedIndex(nextActive);
        }
      }
    } else {
      const distanceToBottom = target.scrollHeight - scrollTop;
      if (
        activeVirtualizedIndex < maxItemIndex &&
        distanceToBottom < getElementHeight() * GROUP_INTERVAL
      ) {
        setActiveVirtualizedIndex((current) => current + 1);
      }
    }
    lastScroll.current = scrollTop;
  };

  const reset = () => {
    setActiveVirtualizedIndex(0);
    lastScroll.current = 0;
  };

  const visibleOptions = useMemo(() => {
    return [
      ...(virtualizedGroups[activeVirtualizedIndex - 1] ?? []),
      ...(virtualizedGroups[activeVirtualizedIndex] ?? []),
      ...(virtualizedGroups[activeVirtualizedIndex + 1] ?? []),
    ];
  }, [activeVirtualizedIndex, virtualizedGroups]);

  return {
    activeVirtualizedIndex,
    fixedBeforeHeight,
    onScroll,
    reset,
    virtualizedGroups,
    visibleOptions,
  };
}
