import { Component } from 'react';

import { ErrorView } from 'components/common';

// TODO: wait for and use error boundary hooks
class ErrorBoundary extends Component {
  state: { error: Error | null } = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <ErrorView
          message={error.message}
          onRetry={() => window.location.reload()}
          title="Something Went Wrong:"
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
