// @ts-ignore
import io from 'socket.io-client';
// @ts-ignore
import wildcard from 'socketio-wildcard';

import { SOCKET_URL } from 'helpers/env';

const patch = wildcard(io.Manager);

export const initSocket = () => {
  const socket = io(SOCKET_URL, {
    autoConnect: false,
    transports: ['websocket'],
  });
  patch(socket);
  return socket;
};
