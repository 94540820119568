import { styled, TextField } from '@mui/material';

import { gray3, gray5, gray7, primaryGreen } from 'styles/colors';

export const StyledTextInput = styled(TextField)({
  '& div': {
    borderRadius: 0,
  },
  '& fieldset': {
    border: 0,
  },
  '& input': {
    padding: '4px',
    textAlign: 'center',
  },
  background: 'transparent',
  color: gray5,
  fontSize: '14px',
  justifyContent: 'center',
  lineHeight: '24px',
  maxWidth: '42px',
});

export const actionButtonStyles = ({
  isAddButton = false,
  isDisabled,
  isLight,
}: {
  isAddButton?: boolean;
  isDisabled: boolean;
  isLight: boolean;
}) => ({
  sx: {
    '& .Mui-disabled': {
      opacity: 0.3,
    },
    '& svg': {
      color: primaryGreen,
    },
    padding: '8px 4px',
    ...(isDisabled && {
      opacity: 0.3,
    }),
    border: `1px solid ${isLight ? gray7 : gray3}`,
    transition: '.2s',
    ...(isAddButton
      ? {
          borderLeft: 0,
          borderRadius: '0 8px 8px 0',
        }
      : {
          borderRadius: '8px 0 0 8px',
          borderRight: 0,
        }),
  },
});
