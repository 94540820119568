import { Box, TextField, type TextFieldProps, Typography } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

import { useTimezoneContext } from 'components/timezones';

interface LocatedDatePickerProps
  extends Omit<DatePickerProps<Date, Date>, 'renderInput'> {
  showCurrentTimeZone?: boolean;
  TextFieldProps?: TextFieldProps | ((props: TextFieldProps) => TextFieldProps);
}

const LocatedDatePicker = forwardRef(function LocatedDatePicker({
  TextFieldProps,
  showCurrentTimeZone = true,
  ...props
}: LocatedDatePickerProps) {
  const { currentTimeZoneAbbreviation } = useTimezoneContext();

  /**
   * This is a hack to get around the fact that the MUI DatePicker component requires luxon DateTime objects after our new implementation
   * @todo change all teh applications to use luxon DateTime objects
   */
  const normalizedProps: Omit<
    DatePickerProps<DateTime, DateTime>,
    'renderInput'
  > = {
    ...props,
    defaultCalendarMonth:
      props.defaultCalendarMonth &&
      DateTime.fromJSDate(props.defaultCalendarMonth),
    /**
     * @todo Need to support getOpenDialogAriaText property. Marked as a follow up cause its more complex
     */
    getOpenDialogAriaText: undefined,
    maxDate: props.maxDate && DateTime.fromJSDate(props.maxDate),
    minDate: props.minDate && DateTime.fromJSDate(props.minDate),

    onAccept: (date) => {
      return props.onAccept?.(date?.toJSDate() ?? null);
    },
    onChange: (date, keyboardInputValue) => {
      return props.onChange(date?.toJSDate() ?? null, keyboardInputValue);
    },
    onError: (reason, value) => {
      return props.onError?.(reason, value?.toJSDate() ?? null);
    },

    onMonthChange: (date) => {
      return props.onMonthChange?.(date?.toJSDate() ?? null);
    },

    onYearChange: (date) => {
      return props.onYearChange?.(date?.toJSDate() ?? null);
    },

    /**
     * @todo Need to support render day property. Marked as a follow up cause its more complex
     */
    renderDay: undefined,

    shouldDisableDate:
      props.shouldDisableDate &&
      ((date) => {
        return props.shouldDisableDate!(date.toJSDate());
      }),

    shouldDisableMonth:
      props.shouldDisableMonth &&
      ((date) => {
        return props.shouldDisableMonth!(date.toJSDate());
      }),

    shouldDisableYear:
      props.shouldDisableYear &&
      ((date) => {
        return props.shouldDisableYear!(date.toJSDate());
      }),
    /**
     * @todo Need to support openTo property. Marked as a follow up cause its more complex
     */
    ToolbarComponent: undefined,
    value: props.value ? DateTime.fromJSDate(new Date(props.value)) : null,
  };

  return (
    <DatePicker<DateTime, DateTime>
      {...normalizedProps}
      renderInput={(props) => {
        const customProps =
          typeof TextFieldProps === 'function'
            ? TextFieldProps(props)
            : TextFieldProps;
        return (
          <TextField
            {...customProps}
            {...props}
            InputProps={{
              ...customProps?.InputProps,
              ...props.InputProps,
              endAdornment: (
                <Box alignItems="center" display="flex" gap={0}>
                  {showCurrentTimeZone && (
                    <Typography
                      fontStyle="italic"
                      variant="caption"
                      whiteSpace="nowrap"
                    >
                      {currentTimeZoneAbbreviation}
                    </Typography>
                  )}
                  {props.InputProps?.endAdornment}
                </Box>
              ),
            }}
          />
        );
      }}
    />
  );
});

export default LocatedDatePicker;
