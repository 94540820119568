export const getSettingsUrl = ({
  ckEventID,
  courseID,
  version = 'v4',
}: {
  ckEventID?: number | null;
  courseID: string | number;
  version?: string;
}) => {
  let url = `/api/${version}/attendance/course/${courseID}/settings`;
  if (ckEventID) url = `${url}?ckEventID=${ckEventID}`;
  return url;
};
