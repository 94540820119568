import React, { createContext, useContext } from 'react';

/**
 * This function returns a Context and a custom hook that will throw an error whenever someone tries to use it outside a provider.
 * - This feature will improve the use of the context, avoiding default values and nullish handlers
 * - Only use it if you want this context to never be used outside a provider
 * @example```tsx
 * // Old way to do
 * const SomeContext = createContext<{test: string}>({test: ''}) //some unnecessary default value that will never be used in this case
 *
 * function SomeComponent() {
 * const value = useContext(SomeContext) // you always needed to import useContext and SomeContext
 * console.log(value?.test) //some unnecessary optional chaining that brings a lot of issues with typing, and in this cases it is not useful
 * }
 * ```
 * @example```tsx
 * // with createRequireContext
 * const [SomeContext, useSomeContext] = createRequiredContext<{test: string}>() // no need to pass default value
 *
 * function SomeComponent() {
 * const value = useSomeContext() // only import the custom hook
 * console.log(value.test) // no need to check if the value is available
 * }
 * ```
 * @param displayName
 * @returns [ContextName, useContextName] the context and a custom hook to use it, T
 *
 */
export function createRequiredContext<P>(
  /** The name that you want to display on the devtools */
  displayName?: string,
  replaceErrorWithDisplayName?: boolean,
): [React.Context<P | null>, () => P] {
  const Context = createContext<P | null>(null);
  if (displayName) {
    Context.displayName = displayName;
  }
  function useCustomUseContext() {
    const context = useContext(Context);
    if (context === null) {
      throw new Error(
        replaceErrorWithDisplayName
          ? displayName
          : `To use this hook, you must be inside the Provider of ${
              Context.displayName ?? 'the required context'
            }`,
      );
    } else {
      return context;
    }
  }
  return [Context, useCustomUseContext];
}
