// USER TYPE INTERFACES
export type UserPermissions = Record<UserPermission, boolean>;

/** These are all global permissions (FE only).
 *  It should centralize all global permission as flags, since our back-end don't have this structure.
 *  - See the `PermissionProvider` to understand the enable/disable rules
 * */
export enum UserPermission {
  /** If the user user has cortex object. */
  HasCortex = 'hasCortex',
  /** If the user has access to any non compliance workspaces. */
  HasWorkspaces = 'hasWorkspaces',
  /** If the user has access to any compliance workspaces. */
  HasComplianceWorkspace = 'hasComplianceWorkspace',
  /** If the hasAgreedToTerms flag is set to true on user profile. */
  HasAgreedToTerms = 'hasAgreedToTerms',
  /** If the hasLicense flag is set to true on user profile. */
  HasLicense = 'hasLicense',
  /** If the hasNotification flag is set to true on user profile. */
  HasNotification = 'hasNotification',
  /** If the hasPrograms flag is set to true on user profile. */
  HasPrograms = 'hasPrograms',
  /** If the hasRetentionDashboard flag is set to true on user profile. */
  HasRetentionDashboard = 'hasRetentionDashboard',
  /** Set to true if the user is schoolAdmin or an instructor with `profile.rotationManagement.enabled` */
  HasRotationManagement = 'hasRotationManagement',
  /** If the managedContent flag is set to true on user profile. */
  HasManagedContent = 'hasManagedContent',
  /** If the isSchoolAdmin flag is set to true on user profile*/
  IsSchoolAdmin = 'isSchoolAdmin',
  /** If the isSupport flag is set to true on user profile*/
  IsSupport = 'isSupport',
  /** If the isTeacher flag is set to true on user profile*/
  IsTeacher = 'isTeacher',
}

export enum UserTypes {
  /** When the user has the flag isSchoolAdmin */
  Administrator = 'Administrator',
  /** When the user has the flag isTeacher */
  Staff = 'Staff',
  /** When the user has the flag isSupport */
  Student = 'Student',
  /** When the user has no other flag active. */
  Support = 'Support',
}
