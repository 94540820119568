import { Box, CircularProgress } from '@mui/material';

const Spinner = ({ ...rest }) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      left={0}
      position="absolute"
      top={0}
      width="100%"
      zIndex={9999}
    >
      <CircularProgress size={48} thickness={3} {...rest} />
    </Box>
  );
};

Spinner.defaultProps = {};

export default Spinner;
